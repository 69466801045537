import React, { useEffect, useRef, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { IonButton, IonContent, IonIcon, IonImg, IonInput, IonItem, IonLabel, IonLoading, IonPage, IonToast } from '@ionic/react';
import { logInOutline as logInIcon, logoGoogle as googleIcon, personAddOutline as signUpIcon, ellipsisHorizontalOutline as recoverIcon } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';
import { signInWithEmailAndPassword, signInWithCredential, GoogleAuthProvider } from 'firebase/auth';
import { useAuth } from '../auth';
import { auth, GoogleAuth } from '../firebase';
import './LoginPage.css';
import { pageTitles } from './helpers/pageTitles';

const LoginPage: React.FC = () => {
	const { t, i18n } = useTranslation();
	const { loggedIn } = useAuth();
	const passRef = useRef<HTMLIonInputElement>();
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [showToast, setShowToast] = useState(false);
	const [toastMsg, setToastMsg] = useState('');
	const [loading, setLoading] = useState(false);
	const [icon, setIcon] = useState<string>('/assets/logo.svg');

	useEffect(() => {
		setIcon(window.matchMedia('(prefers-color-scheme: dark)').matches ? '/assets/logo-dark.svg' : '/assets/logo.svg');
		document.title = pageTitles[i18n.language];
	}, [i18n]);

	const ERR_MSGS = {
		'auth/wrong-password': t('login.errorMsg.wrongPassword'),
		'auth/too-many-requests': t('login.errorMsg.tooManyRequests'),
		'auth/user-not-found': t('login.errorMsg.userNotFound'),
		'auth/invalid-email': t('login.errorMsg.invalidEmail'),
		'auth/user-disabled': t('login.errorMsg.userDisabled'),
	};

	const handleLogin = async () => {
		try {
			if (email.trim() === '' || password.trim() === '') return;

			setLoading(true);
			await signInWithEmailAndPassword(auth, email, password);
			setLoading(false);
		} catch (e) {
			console.log(e.code);
			setLoading(false);
			setToastMsg(ERR_MSGS[e.code.toString()] || t('login.errorMsg.fallback'));
			setShowToast(true);
		}
	};

	const handleLoginGoogle = async () => {
		try {
			const googleUser = await GoogleAuth.signIn();
			const credential = GoogleAuthProvider.credential(googleUser.authentication.idToken);
			await signInWithCredential(auth, credential);
		} catch (error) {
			console.log(error);
			setToastMsg(error.message);
			setShowToast(true);
		}
	};

	if (loggedIn) {
		return <Redirect to='/app/collection/main' />;
	}

	return (
		<IonPage>
			<IonContent className='ion-padding form'>
				<div className='items'>
					<header>
						<IonImg src={icon} className='app-icon' />
						<h2>{t('login.title')}</h2>
					</header>
					<div className='input-fields'>
						<IonItem className='input'>
							<IonLabel position='floating'>{t('login.labelMail')}</IonLabel>
							<IonInput
								type='email'
								value={email}
								onIonChange={(e) => setEmail(e.detail.value)}
								onKeyUp={(e) => e.key === 'Enter' && passRef.current.setFocus()}
							></IonInput>
						</IonItem>
						<IonItem className='input'>
							<IonLabel position='floating'>{t('login.labelPassword')}</IonLabel>
							<IonInput
								type='password'
								value={password}
								onIonChange={(e) => setPassword(e.detail.value)}
								onKeyUp={(e) => e.key === 'Enter' && handleLogin()}
								ref={passRef}
							></IonInput>
						</IonItem>

						<IonItem className='others'>
							<IonButton type='button' fill='clear' color='primary' slot='start' routerLink='/register'>
								{t('login.btnRegister')}
								<IonIcon slot='end' icon={signUpIcon} />
							</IonButton>
							<IonButton type='button' fill='clear' color='primary' slot='end' routerLink='/recovery'>
								{t('login.btnRecovery')}
								<IonIcon slot='end' icon={recoverIcon} />
							</IonButton>
						</IonItem>
					</div>

					<IonButton expand='block' shape='round' className='login-btn' onClick={handleLogin}>
						<IonIcon icon={logInIcon} slot='start'></IonIcon>
						{t('login.btnLogIn')}
					</IonButton>

					<IonButton expand='block' color='primary' shape='round' className='login-btn' onClick={handleLoginGoogle}>
						<IonIcon icon={googleIcon} slot='start'></IonIcon>
						{t('login.btnLogInGoogle')}
					</IonButton>
				</div>

				<IonToast isOpen={showToast} onDidDismiss={() => setShowToast(false)} message={toastMsg} duration={5000} position='bottom'></IonToast>
				<IonLoading isOpen={loading} />
			</IonContent>
		</IonPage>
	);
};

export default LoginPage;
