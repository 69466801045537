export const picmoTranslation = {
	en: {
		'categories.activities': 'Activities',
		'categories.animals-nature': 'Animals & Nature',
		'categories.custom': 'Custom',
		'categories.flags': 'Flags',
		'categories.food-drink': 'Food & Drink',
		'categories.objects': 'Objects',
		'categories.people-body': 'People & Body',
		'categories.recents': 'Recently Used',
		'categories.smileys-emotion': 'Smileys & Emotion',
		'categories.symbols': 'Symbols',
		'categories.travel-places': 'Travel & Places',

		'error.load': 'Failed to load emojis',

		'recents.clear': 'Clear recent emojis',
		'recents.none': "You haven't selected any emojis yet.",

		retry: 'Try again',

		'search.clear': 'Clear search',

		'search.error': 'Failed to search emojis',

		'search.notFound': 'No results found',

		search: 'Search emojis...',
	},
	es: {
		'categories.activities': 'Actividades',
		'categories.animals-nature': 'Animales y Naturaleza',
		'categories.custom': 'Custom',
		'categories.flags': 'Banderas',
		'categories.food-drink': 'Comida y Bebidas',
		'categories.objects': 'Objetos',
		'categories.people-body': 'Personas y Cuerpos',
		'categories.recents': 'Usados Recientemente',
		'categories.smileys-emotion': 'Smileys y Emociones',
		'categories.symbols': 'Símbolos',
		'categories.travel-places': 'Viajes y Lugares',

		'error.load': 'Fallo al cargar emojis',

		'recents.clear': 'Borrar emojis reciente',
		'recents.none': 'Aún no has seleccionado un emoji',

		retry: 'Volver a Intentar',

		'search.clear': 'Limpiar búsqueda',

		'search.error': 'Fallo al buscar emojis',

		'search.notFound': 'No hay resultados',

		search: 'Buscar emojis...',
	},
};
