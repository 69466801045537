import React, { useEffect, useState } from 'react';
import { IonCol, IonGrid, IonRow, IonToolbar, isPlatform } from '@ionic/react';
import Container from './Container';
import { ContainerModel } from '../models';
import './SavedContainers.css';

const SavedContainers = ({ containers, clickSavedContainer }: { containers: ContainerModel[]; clickSavedContainer: any }) => {
	const [isMobile, setIsMobile] = useState<boolean>(false);

	const createDummy = (id) => {
		return {
			id: id,
			parentId: '',
			name: '---',
			image: '',
			thumbnail: '',
			emoji: '',
			description: '',
			border: '1px solid rgba(128, 128, 128, 0.15)',
			tags: [],
			position: 99999,
			active: false,
			background: '',
			isTransparent: false,
			isGrayscale: false,
		};
	};
	const [displayContainers, setDisplayContainers] = useState([
		createDummy(1),
		createDummy(2),
		createDummy(3),
		createDummy(4),
		createDummy(5),
		createDummy(6),
		createDummy(7),
		createDummy(8),
		createDummy(9),
		createDummy(10),
	]);

	useEffect(() => {
		setIsMobile(isPlatform('mobile'));

		const tempArr = [];
		for (let i = 0; i < 10; i++) {
			if (containers[i]) {
				tempArr[i] = containers[i];
			} else {
				tempArr[i] = createDummy(i);
			}
		}
		setDisplayContainers(tempArr);
	}, [containers]);

	return (
		<div>
			{isMobile && (
				<IonToolbar className={`saved-containers-toolbar ${containers.length > 0 ? '' : 'saved-containers-toolbar--hidden'}`}>
					<IonGrid fixed={true} className='saved-containers-grid'>
						<IonRow className='ion-justify-content-around ion-align-items-center'>
							{displayContainers.slice(0, 5).map((cont) => (
								<IonCol
									key={cont.id}
									data-id={cont.id}
									className={`saved-container-col saved-container ${cont.active ? '' : 'saved-container--inactive'}`}
									size='2'
								>
									<div onClick={clickSavedContainer}>
										<Container data={cont} />
									</div>
								</IonCol>
							))}
						</IonRow>
						<IonRow className='ion-justify-content-around ion-align-items-center'>
							{displayContainers.slice(5, 10).map((cont) => (
								<IonCol
									key={cont.id}
									data-id={cont.id}
									className={`saved-container-col saved-container ${cont.active ? '' : 'saved-container--inactive'}`}
									size='2'
								>
									<div className='saved-containers-events' onClick={clickSavedContainer}>
										<Container data={cont} />
									</div>
								</IonCol>
							))}
						</IonRow>
					</IonGrid>
				</IonToolbar>
			)}
			{!isMobile && (
				<IonToolbar className={`saved-containers-toolbar-large ${containers.length > 0 ? '' : 'saved-containers-toolbar-large--hidden'}`}>
					<IonGrid fixed={true} className='saved-containers-grid-large'>
						<IonRow className='ion-justify-content-around ion-align-items-center'>
							{displayContainers.map((cont) => (
								<IonCol
									key={cont.id}
									data-id={cont.id}
									className={`saved-container-col saved-container ${cont.active ? '' : 'saved-container--inactive'}`}
									size='2'
								>
									<div onClick={clickSavedContainer}>
										<Container data={cont} />
									</div>
								</IonCol>
							))}
						</IonRow>
					</IonGrid>
				</IonToolbar>
			)}
		</div>
	);
};

export default SavedContainers;
