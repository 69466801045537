import React, { useEffect, useState } from 'react';
import { IonAvatar, IonImg, IonItem, IonLabel, IonSkeletonText } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import './ProfileTile.css';
import { ProfileDisplay } from '../models';

const ProfileTile = ({ profile }: { profile: ProfileDisplay }) => {
	const { t } = useTranslation();
	const [displayName, setDisplayName] = useState<string>('');
	const [displayImage, setDisplayImage] = useState<string>('');
	const [loaded, setLoaded] = useState<boolean>(false);

	useEffect(() => {
		setDisplayName(profile?.displayName || t('groups.banner.default'));
		setDisplayImage(profile?.image || '/assets/avatar.svg');
		setLoaded(true);
	}, [t, profile]);

	return (
		<div className='profile-tile'>
			<IonItem className='profile-tile-item'>
				<IonAvatar slot='start'>
					{(!loaded || profile?.shouldAvoidLoad) && <IonSkeletonText animated={true}></IonSkeletonText>}
					<IonImg
						alt='Avatar'
						src={displayImage}
						onIonImgDidLoad={() => setLoaded(true)}
						onIonImgWillLoad={() => setLoaded(false)}
						className={loaded && !profile?.shouldAvoidLoad ? '' : 'hidden'}
					/>
				</IonAvatar>
				<IonLabel>{!profile?.shouldAvoidLoad ? displayName : <IonSkeletonText animated={true}></IonSkeletonText>}</IonLabel>
			</IonItem>
		</div>
	);
};

export default ProfileTile;
