import React, { useEffect, useState } from 'react';
import {
	IonButton,
	IonButtons,
	IonContent,
	IonHeader,
	IonIcon,
	IonImg,
	IonItem,
	IonLabel,
	IonList,
	IonMenu,
	IonMenuToggle,
	IonTitle,
	IonToolbar,
} from '@ionic/react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Browser } from '@capacitor/browser';
import { globe as webBrowserPageIcon } from 'ionicons/icons';
import { collection, getDocs } from 'firebase/firestore';
import { menuPages } from './MenuPages';
import { db } from '../firebase';
import { WebBrowserMenuPage, toWebBrowserMenuPageArr } from '../models';
import Profile from './Profile';
import { UserProfile } from '../models';
import './Menu.css';

interface menuProps {
	theme: string;
	profile: UserProfile;
}

const Menu: React.FC<menuProps> = ({ theme, profile }) => {
	const location = useLocation();
	const [icon, setIcon] = useState<string>('/assets/logo.svg');
	const { t } = useTranslation();
	const [extraPages, setExtraPages] = useState<WebBrowserMenuPage[]>([]);

	useEffect(() => {
		const getPages = async () => {
			const pages = await getDocs(collection(db, 'menuLinkPages'));
			setExtraPages(toWebBrowserMenuPageArr(pages.docs));
		};

		getPages();
	}, []);

	useEffect(() => {
		setIcon(theme === 'light' ? '/assets/logo.svg' : '/assets/logo-dark.svg');
	}, [theme]);

	const openSite = async (url) => {
		await Browser.open({ url });
	};

	return (
		<IonMenu contentId='main' type='overlay'>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot='start'>
						<IonButton>
							<IonImg src={icon} className='app-logo' />
						</IonButton>
					</IonButtons>
					<IonTitle>{t('menu.appTitle')}</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				<Profile profile={profile} />
				<IonList id='inbox-list'>
					{menuPages.map((appPage, index) => {
						return (
							<IonMenuToggle key={index} autoHide={false}>
								<IonItem
									className={location.pathname === appPage.url ? 'selected' : ''}
									routerLink={appPage.url}
									routerDirection='none'
									lines='none'
									detail={false}
								>
									<IonIcon slot='start' icon={appPage.icon} />
									<IonLabel>{t(`menu.pages.${appPage.title}`)}</IonLabel>
								</IonItem>
							</IonMenuToggle>
						);
					})}
					{extraPages.map((page, index) => {
						return (
							<IonMenuToggle key={index} autoHide={false}>
								<IonItem lines='none' detail={false} onClick={() => openSite(page.url)} routerLink={location.pathname} routerDirection='none'>
									<IonIcon slot='start' icon={webBrowserPageIcon} />
									<IonLabel>{page.title}</IonLabel>
								</IonItem>
							</IonMenuToggle>
						);
					})}
				</IonList>
			</IonContent>
		</IonMenu>
	);
};

export default Menu;
