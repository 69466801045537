import React, { useEffect, useRef, useState } from 'react';
import {
	IonButton,
	IonButtons,
	IonCol,
	IonContent,
	IonGrid,
	IonHeader,
	IonIcon,
	IonInfiniteScroll,
	IonInfiniteScrollContent,
	IonMenuButton,
	IonPage,
	IonRow,
	IonTitle,
	IonToolbar,
	IonLoading,
	useIonActionSheet,
	useIonViewWillEnter,
} from '@ionic/react';
import { useHistory } from 'react-router';
import { chevronBackCircle as backIcon, closeCircle as cancelSearchIcon } from 'ionicons/icons';
import Container from '../components/Container';
import SearchBar from '../components/SearchBar';
import useStateRef from 'react-usestateref';
import { uniqWith, isEqual } from 'lodash-es';
import useLongPress from '../hooks/useLongPress';
import { ContainerModel, toContainerArr } from '../models';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../auth';
import { query, getDocs, where, Query, QuerySnapshot, DocumentData, collectionGroup, orderBy, limit } from 'firebase/firestore';
import { db } from '../firebase';
import './ContainerSearchPage.css';
import SavedContainers from '../components/SavedContainers';

interface SearchProps {
	setSearchTerm: Function;
	searchTerm: string;
	lastCollection: string;
	savedContainers: ContainerModel[];
	addContainerToSaved: Function;
	deleteAllSavedContainers: Function;
	removeSavedContainer: Function;
}

const ContainerSearchPage: React.FC<SearchProps> = ({
	setSearchTerm,
	searchTerm,
	lastCollection,
	savedContainers,
	addContainerToSaved,
	removeSavedContainer,
}) => {
	const { userId } = useAuth();
	const { t } = useTranslation();
	const history = useHistory();
	const [containers, setContainers] = useState<ContainerModel[]>([]);
	const [displayNumber, setDisplayNumber] = useState<number>(60);
	const [selectedId, setSelectedId, selectedIdRef] = useStateRef<string>('');
	const [selectedContainer, setSelectedContainer, selectedContainerRef] = useStateRef<ContainerModel>(null);
	const [infiniteDisabled, setInfiniteDisabled] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const contentRef = useRef<HTMLIonContentElement>();
	const containerDisplayRef = useRef<HTMLIonRowElement>();
	const page = useRef();
	const [presentActions] = useIonActionSheet();
	const containersPerScrollLoad = 60;
	//TODO replace with value from database assigned to user
	const maxContPerView = 300;

	// Initial data fetch to get information about the collection
	useIonViewWillEnter(() => {
		setDisplayNumber(60);
		setInfiniteDisabled(false);
	}, [lastCollection, userId]);

	useEffect(() => {
		const getQueries = () => {
			const queries = [];

			const contRef = collectionGroup(db, 'containers');

			queries.push(
				query(
					contRef,
					where('searchName', 'array-contains', searchTerm.toLowerCase()),
					where('roles.owner', '==', userId),
					orderBy('lastEdit', 'desc'),
					limit(100)
				)
			);
			queries.push(
				query(
					contRef,
					where('tags', 'array-contains', searchTerm.toLowerCase()),
					where('roles.owner', '==', userId),
					orderBy('lastEdit', 'desc'),
					limit(100)
				)
			);
			queries.push(query(contRef, where('emoji', '==', searchTerm), where('roles.owner', '==', userId), orderBy('lastEdit', 'desc'), limit(100)));

			return queries;
		};

		const getContainers = async () => {
			setIsLoading(true);
			const queries = getQueries() as Query[];

			const querySnaps = await Promise.all(queries.map((query) => getDocs(query)));
			const resultsRaw = [...querySnaps];
			const results = uniqWith(resultsRaw, isEqual) as QuerySnapshot<DocumentData>[];
			const arr = results
				.map((res) => toContainerArr(res.docs))
				.filter((res) => res.length > 0)
				.flat();

			setContainers(arr);
			setIsLoading(false);
		};

		getContainers();
	}, [searchTerm, lastCollection, userId]);

	// Check if the current containers fill the view. If not, load more.
	useEffect(() => {
		const checkForScroll = async () => {
			const scrollEl = await contentRef.current.getScrollElement();
			const hasScroll = scrollEl.scrollHeight > scrollEl.clientHeight;
			const shouldLoadMore = !hasScroll && containers.length < maxContPerView;
			shouldLoadMore && setDisplayNumber(Math.min(displayNumber + containersPerScrollLoad, maxContPerView));
		};

		!infiniteDisabled && checkForScroll();
	}, [containers, displayNumber, infiniteDisabled]);

	// Handler for the IonInfiniteScroll component, triggers data fetch when the user scrolls down to the bottom.
	const loadData = async (ev: CustomEvent<void>) => {
		setDisplayNumber(Math.min(displayNumber + containersPerScrollLoad, maxContPerView));
		await (ev.target as HTMLIonInfiniteScrollElement).complete();

		if (containers.length >= maxContPerView) {
			setInfiniteDisabled(true);
		}
	};

	// Handler for the back button to go back to the previous collection
	const handleBackButton = () => {
		history.push(`/app/collection/${lastCollection}`);
	};

	const onLongPress = (e) => {
		const showOptions = () => {
			const options = [
				{
					text: t('search.actionLongPress.buttons.view'),
					role: 'destructive',
					data: {
						action: 'view',
					},
				},
				{
					text: t('search.actionLongPress.buttons.save'),
					role: 'destructive',
					data: {
						action: 'save',
					},
				},
			];

			if (selectedContainerRef.current.isParent) {
				options.push({
					text: t('search.actionLongPress.buttons.open'),
					role: 'destructive',
					data: {
						action: 'open',
					},
				});
			}

			return options;
		};

		const { dataset } = e.target.closest('.container-col');
		if (!dataset) return;
		setSelectedId(String(dataset.id));
		setSelectedContainer(containers.find((cont) => cont.id === dataset.id));
		console.log(selectedContainer);

		presentActions({
			header: t('search.actionLongPress.header'),
			cssClass: 'actions',
			buttons: [
				...showOptions(),
				{
					text: t('search.actionLongPress.buttons.cancel'),
					role: 'cancel',
					data: {
						action: 'cancel',
					},
				},
			],
			onDidDismiss: ({ detail }) => {
				if (detail.data?.action === 'view') handleView();
				if (detail.data?.action === 'open') handleOpen();
				if (detail.data?.action === 'save') handleSave();

				if (detail.role === 'cancel' || detail.role === 'backdrop') {
					setSelectedId('');
					setSelectedContainer(null);
				}
			},
		});
	};

	const onClick = (e) => {
		const dataset = e.target.closest('.container')?.dataset;
		if (!dataset) return;

		if (dataset.isParent) {
			handleOpen(containers.find((cont) => cont.id === dataset.id).childCollection);
		}
	};

	const handler = useLongPress(
		{ onLongPress, onClick },
		{
			shouldPreventDefault: true,
			delay: 400,
		}
	);

	const handleView = () => {
		history.push(`/app/view/${selectedContainerRef.current.parentId}/${selectedIdRef.current}`);
		setSelectedId('');
		setSelectedContainer(null);
	};

	const handleOpen = (collection = null) => {
		history.push(`/app/collection/${collection === null ? selectedContainerRef.current.childCollection : collection}`);
		setSelectedId('');
		setSelectedContainer(null);
	};

	const containerClass = (containerId) => {
		if (containerId === selectedId) return 'container--selected';
		return '';
	};

	const cancelSearch = () => {
		setSearchTerm('');
	};

	const handleSearch = (e) => {
		e.preventDefault();

		if (e.key === 'Enter') {
			setSearchTerm(e.target.value);
		}
	};

	const handleSave = () => {
		addContainerToSaved(selectedContainerRef.current);
		setSelectedId('');
		setSelectedContainer(null);
	};

	const clickSavedContainer = (e) => {
		const { dataset } = e.target.closest('.saved-container-col');
		if (!dataset) return;

		const selectedId = String(dataset.id);
		if (!selectedId) return;

		const selectedSavedContainer = savedContainers.find((cont) => cont.id === dataset.id);
		if (!selectedSavedContainer) return;

		setSelectedId(selectedId);
		setSelectedContainer(selectedSavedContainer);

		presentActions({
			header: t('display.actionClickSaved.header'),
			cssClass: 'actions',
			buttons: [
				{
					text: t('display.actionClickSaved.buttons.remove'),
					role: 'destructive',
					data: {
						action: 'remove',
					},
				},
				{
					text: t('display.actionClickSaved.buttons.cancel'),
					role: 'cancel',
					data: {
						action: 'cancel',
					},
				},
			],
			onDidDismiss: ({ detail }) => {
				if (detail.data?.action === 'remove') handleRemoveSaved();

				if (detail.role === 'cancel' || detail.role === 'backdrop') {
					setSelectedId('');
					setSelectedContainer(null);
				}
			},
		});
	};

	const handleRemoveSaved = async () => {
		removeSavedContainer(selectedContainerRef.current.id);
		setSelectedId('');
		setSelectedContainer(null);
	};

	return (
		<IonPage ref={page}>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot='start'>
						<IonMenuButton />
						<IonButton onClick={handleBackButton}>
							<IonIcon slot='icon-only' icon={backIcon} />
						</IonButton>
					</IonButtons>
					<SearchBar handleSearch={handleSearch} cancelSearch={cancelSearch}></SearchBar>
				</IonToolbar>
				<IonToolbar>
					<IonButtons slot='end'>
						<IonButton onClick={handleBackButton}>
							<IonIcon slot='icon-only' icon={cancelSearchIcon} />
						</IonButton>
					</IonButtons>
					<IonTitle>
						{t('display.titleSearchingFor')} "{searchTerm}"
					</IonTitle>
				</IonToolbar>
				<SavedContainers containers={savedContainers} clickSavedContainer={clickSavedContainer} />
			</IonHeader>

			<IonContent className='ion-padding' ref={contentRef}>
				<IonGrid fixed={true}>
					<IonRow ref={containerDisplayRef}>
						{containers?.map((cont) => (
							<IonCol className={`container-col ${containerClass(cont.id)}`} key={cont.id} data-id={cont.id}>
								<Container data={cont} handler={handler}></Container>
							</IonCol>
						))}
					</IonRow>
				</IonGrid>
				<IonInfiniteScroll onIonInfinite={loadData} threshold='100px' disabled={infiniteDisabled}>
					<IonInfiniteScrollContent loadingSpinner='circular' loadingText={t('display.scrollLoadingText')}></IonInfiniteScrollContent>
				</IonInfiniteScroll>
				<IonLoading isOpen={isLoading} message={t('search.loadingSearch')} />
			</IonContent>
		</IonPage>
	);
};

export default ContainerSearchPage;
