import React, { useState } from 'react';
import { IonButton, IonContent, IonIcon, IonInput, IonItem, IonLabel, IonLoading, IonNote, IonPage, IonToast } from '@ionic/react';
import { mailOutline as recoveryIcon, arrowBack as backIcon } from 'ionicons/icons';
import { auth } from '../firebase';
import { sendPasswordResetEmail } from 'firebase/auth';
import { useTranslation } from 'react-i18next';
import './LoginPage.css';

const RecoveryPage: React.FC = () => {
	const { t } = useTranslation();
	const [email, setEmail] = useState('');
	const [isValidMail, setIsValidMail] = useState<boolean>();
	const [isTouchedMail, setIsTouchedMail] = useState<boolean>(false);
	const [showToast, setShowToast] = useState(false);
	const [toastMsg, setToastMsg] = useState('');
	const [loading, setLoading] = useState(false);

	const ERR_MSGS = {
		'auth/too-many-requests': t('recovery.errorMsg.tooManyRequests'),
		'auth/invalid-email': t('recovery.errorMsg.invalidEmail'),
		'auth/user-not-found': t('recovery.errorMsg.userNotFound'),
	};

	const validateMail = (ev: Event) => {
		const validateEmail = (email: string) => {
			return email.match(
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			);
		};

		const value = (ev.target as HTMLInputElement).value;
		if (value === '') return;
		validateEmail(value) !== null ? setIsValidMail(true) : setIsValidMail(false);
	};

	const handleRecovery = async () => {
		try {
			setLoading(true);
			await sendPasswordResetEmail(auth, email);
			setLoading(false);

			setToastMsg(t('recovery.toastMailSent'));
			setShowToast(true);
		} catch (e) {
			console.log(e.code);
			setLoading(false);
			setToastMsg(ERR_MSGS[e.code.toString()] || t('recovery.errorMsg.fallback'));
			setShowToast(true);
		}
	};

	return (
		<IonPage>
			<IonContent className='ion-padding form'>
				<div className='items'>
					<header>
						<h2>{t('recovery.title')}</h2>
					</header>
					<div className='input-fields'>
						<IonItem className={`input ${isValidMail && 'ion-valid'} ${isValidMail === false && 'ion-invalid'}`}>
							<IonLabel position='floating'>{t('recovery.labelMail')}</IonLabel>
							<IonInput
								type='email'
								value={email}
								onIonChange={(e) => setEmail(e.detail.value)}
								onIonInput={(event) => validateMail(event)}
								onIonBlur={() => setIsTouchedMail(true)}
								onKeyUp={(e) => e.key === 'Enter' && handleRecovery()}
							></IonInput>
							{isTouchedMail && !isValidMail && (
								<IonNote slot='error' className='input-fields-error'>
									{t('recovery.noteInvalidMail')}
								</IonNote>
							)}
						</IonItem>
					</div>

					<IonButton expand='block' shape='round' className='login-btn' onClick={handleRecovery}>
						<IonIcon icon={recoveryIcon} slot='start'></IonIcon>
						{t('recovery.btnRecovery')}
					</IonButton>

					<IonButton expand='block' color='primary' shape='round' className='login-btn' routerLink='/login'>
						<IonIcon icon={backIcon} slot='start'></IonIcon>
						{t('recovery.btnGoBack')}
					</IonButton>
				</div>

				<IonToast isOpen={showToast} onDidDismiss={() => setShowToast(false)} message={toastMsg} duration={5000} position='bottom'></IonToast>
				<IonLoading isOpen={loading} />
			</IonContent>
		</IonPage>
	);
};

export default RecoveryPage;
