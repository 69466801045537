import React, { useEffect, useRef, useState } from 'react';
import {
	IonButton,
	IonButtons,
	IonContent,
	IonFooter,
	IonHeader,
	IonIcon,
	IonInput,
	IonItem,
	IonLabel,
	IonMenuButton,
	IonPage,
	IonTextarea,
	IonTitle,
	IonToast,
	IonToolbar,
	isPlatform,
	useIonLoading,
	useIonViewWillEnter,
	useIonViewWillLeave,
} from '@ionic/react';
import { pencil as usernameIcon, image as imageIcon, book as bioIcon } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../auth';
import { db, storage } from '../firebase';
import { doc, setDoc, Timestamp, collectionGroup, query, where, getDocs } from 'firebase/firestore';
import { compressToJPEG } from './helpers/compressImage';
import ReactCrop, { centerCrop, Crop, makeAspectCrop, PixelCrop } from 'react-image-crop';
import { imgPreview } from './helpers/imgPreview';
import './SettingsProfilePage.css';
import { UserProfile } from '../models';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';

interface SettingsProfileProps {
	profile: UserProfile;
	setProfile: Function;
}

const SettingsProfilePage: React.FC<SettingsProfileProps> = ({ profile, setProfile }) => {
	const { userId, userProfile } = useAuth();
	const { t } = useTranslation();
	const fileInputRef = useRef<HTMLInputElement>();
	const imgRef = useRef<HTMLImageElement>();
	const [presentLoading, dismissLoading] = useIonLoading();
	const [username, setUsername] = useState<string>('');
	const [bio, setBio] = useState<string>('');
	const [showToast, setShowToast] = useState<boolean>(false);
	const [toastMsg, setToastMsg] = useState<string>('');
	const [isNewImage, setIsNewImage] = useState<boolean>(false);
	const [imageURL, setImageURL] = useState<string>('/assets/images/placeholder.png');
	const [thumbnailURL, setThumbnailURL] = useState<string>('/assets/images/placeholder.png');
	const [crop, setCrop] = useState<Crop>();
	const [completedCrop, setCompletedCrop] = useState<PixelCrop>();

	useIonViewWillEnter(() => {
		setToastMsg('');
		setUsername(userProfile?.displayName || '');
		setBio(userProfile?.bio || '');
		setIsNewImage(false);
	});

	useIonViewWillLeave(() => {
		setIsNewImage(false);
		setThumbnailURL('/assets/images/placeholder.png');
		setImageURL('/assets/images/placeholder.png');
	});

	const handleClickBtnChange = () => {
		if (isNewImage) {
			if (imageURL.startsWith('blob:')) URL.revokeObjectURL(imageURL);
		}

		if (!isNewImage) fileInputRef.current.click();

		setIsNewImage(!isNewImage);
	};

	const handleFileChange = async (event) => {
		if (event.target.files.length === 0) return;

		setCrop(undefined);
		const file = event.target.files.item(0);
		const compressedFile = await compressToJPEG(file, 200);
		const imageURL = URL.createObjectURL(compressedFile);
		setImageURL(imageURL);
	};

	const handleImageClick = async () => {
		if (isPlatform('capacitor')) {
			fileInputRef.current.click();
		} else {
			fileInputRef.current.click();
		}
	};

	const onImageLoad = (e) => {
		const { naturalWidth: width, naturalHeight: height } = e.currentTarget;

		const crop = centerCrop(
			makeAspectCrop(
				{
					unit: '%',
					width: 40,
				},
				1,
				width,
				height
			),
			width,
			height
		);

		setCrop(crop);
	};

	useEffect(() => {
		const generateThumbnail = async () => {
			if (isNewImage && completedCrop?.width && completedCrop?.height && imgRef.current) {
				const thumb = await imgPreview(imgRef.current, completedCrop);
				setThumbnailURL(thumb);
			}
		};

		generateThumbnail();
	}, [completedCrop, imageURL, isNewImage]);

	useEffect(() => {
		return () => {
			if (imageURL.startsWith('blob:')) {
				URL.revokeObjectURL(imageURL);
			}
		};
	}, [imageURL]);

	const handleUsernameChange = (e) => {
		setUsername(e.detail.value);
		setProfile({ image: profile?.image, displayName: e.detail.value });
	};

	useEffect(() => {
		if (isNewImage) {
			setProfile({ image: thumbnailURL, displayName: profile?.displayName });
		}
	}, [thumbnailURL, setProfile, profile?.displayName, isNewImage]);

	const handleApply = async () => {
		const checkIfUsernameAvailable = async (userId, username) => {
			const q = query(collectionGroup(db, 'profile'), where('displayName', '==', username));
			const snap = await getDocs(q);
			const user = snap.docs[0]?.data();

			return user?.id === userId || !user;
		};

		const uploadImages = async (userId, imageUrl, thumbnailUrl): Promise<string[]> => {
			const [imageResponse, thumbResponse] = await Promise.all([fetch(imageUrl), fetch(thumbnailUrl)]);
			const [imageBlob, thumbBlob] = await Promise.all([imageResponse.blob(), thumbResponse.blob()]);

			const pictureRef = ref(storage, `/users/${userId}/profile/image`);
			const thumbRef = ref(storage, `/users/${userId}/profile/thumbnail`);

			const [imageTask, thumbTask] = await Promise.all([uploadBytes(pictureRef, imageBlob), uploadBytes(thumbRef, thumbBlob)]);

			return await Promise.all([getDownloadURL(imageTask.ref), getDownloadURL(thumbTask.ref)]);
		};

		if (!username) {
			setToastMsg(t('settings.profile.toastNameEmpty'));
			setShowToast(true);
			return;
		}

		await presentLoading({
			message: t('settings.profile.loadingApply'),
		});

		const available = await checkIfUsernameAvailable(userId, username);

		if (!available) {
			setToastMsg(t('settings.profile.toastNameNotAvailable'));
			setShowToast(true);
			await dismissLoading();
			return;
		}

		if (!imageURL.startsWith('/assets') || isNewImage) {
			const [newImageUrl, newThumbUrl] = await uploadImages(userId, imageURL, thumbnailURL);

			await setDoc(
				doc(db, 'users', userId, 'profile', 'data'),
				{
					fullImage: newImageUrl,
					image: newThumbUrl,
					displayName: username,
					id: userId,
					lastUpdate: Timestamp.now(),
					bio: bio,
				},
				{ merge: true }
			);
		}

		if (!isNewImage) {
			await setDoc(
				doc(db, 'users', userId, 'profile', 'data'),
				{
					displayName: username,
					id: userId,
					lastUpdate: Timestamp.now(),
					bio: bio,
				},
				{ merge: true }
			);
		}

		await dismissLoading();
		window.location.reload();
	};

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot='start'>
						<IonMenuButton />
					</IonButtons>
					<IonTitle>{t('settings.profile.title')}</IonTitle>
				</IonToolbar>
			</IonHeader>

			<IonContent className='ion-padding'>
				<IonItem lines='full'>
					<IonIcon slot='start' icon={usernameIcon} />
					<IonLabel>{t('settings.profile.labelUsername')}</IonLabel>
					<IonInput
						slot='end'
						placeholder={t('settings.profile.placeholderUsername')}
						value={username}
						onIonChange={(e) => handleUsernameChange(e)}
						style={{ textAlign: 'right' }}
					/>
				</IonItem>
				<IonItem lines='full'>
					<IonIcon slot='start' icon={imageIcon} />
					<IonLabel>{t('settings.profile.labelImage')}</IonLabel>
					<IonButton slot='end' onClick={handleClickBtnChange}>
						{isNewImage ? t('settings.profile.btnCancelChange') : t('settings.profile.btnChangeImage')}
					</IonButton>
					<input type='file' accept='image/*' alt={t('edit.labelImg')} onChange={handleFileChange} ref={fileInputRef} hidden />
					{isNewImage && (
						<ReactCrop
							crop={crop}
							onChange={(c) => setCrop(c)}
							aspect={1}
							className='container-crop'
							onComplete={(c) => setCompletedCrop(c)}
							disabled={!isNewImage}
							ruleOfThirds={true}
						>
							<img
								src={imageURL}
								alt=''
								onClick={handleImageClick}
								style={{ cursor: 'pointer' }}
								className='container-uploaded-image'
								onLoad={onImageLoad}
								ref={imgRef}
							/>
						</ReactCrop>
					)}
				</IonItem>
				<IonItem lines='full'>
					<IonIcon slot='start' icon={bioIcon} />
					<IonLabel>{t('settings.profile.labelBio')}</IonLabel>
					<IonTextarea slot='end' value={bio} rows={1} autoGrow={true} onIonChange={(e) => setBio(e.detail.value)} style={{ textAlign: 'right' }} />
				</IonItem>
				<IonToast isOpen={showToast} onDidDismiss={() => setShowToast(false)} message={toastMsg} duration={2000} position='bottom'></IonToast>
			</IonContent>
			<IonFooter>
				<IonToolbar style={{ padding: '10px' }}>
					<IonButton color='primary' expand='block' onClick={handleApply} style={{ marginBottom: '20px' }}>
						{t('settings.profile.btnApply')}
					</IonButton>
				</IonToolbar>
			</IonFooter>
		</IonPage>
	);
};

export default SettingsProfilePage;
