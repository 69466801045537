import React, { useEffect, useRef, useState } from 'react';
import {
	IonButton,
	IonButtons,
	IonCol,
	IonContent,
	IonGrid,
	IonHeader,
	IonIcon,
	IonInfiniteScroll,
	IonInfiniteScrollContent,
	IonMenuButton,
	IonPage,
	IonRow,
	IonTitle,
	IonToolbar,
	IonModal,
	useIonActionSheet,
	useIonToast,
	useIonLoading,
	useIonViewWillLeave,
	useIonViewWillEnter,
	IonAvatar,
	useIonViewDidEnter,
} from '@ionic/react';
import { useHistory, useParams } from 'react-router';
import { chevronBackCircle as backIcon } from 'ionicons/icons';
import Container from '../components/Container';
import useStateRef from 'react-usestateref';
import useLongPress from '../hooks/useLongPress';
import { ContainerModel, ContainerCollection, toContainer, toContainerCollection, GroupInfo, toGroupInfo } from '../models';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../auth';
import { doc, setDoc, collection, onSnapshot, query, orderBy, limit, updateDoc, deleteDoc, Timestamp, addDoc, getDoc, writeBatch } from 'firebase/firestore';
import { db } from '../firebase';
import './ContainerDisplayGroupsPage.css';
import SavedContainers from '../components/SavedContainers';

const createPlaceholderContainers = (parentId: string, num: number): Array<ContainerModel> => {
	const arr = [];

	for (let i = 0; i < num; i++) {
		arr.push({
			id: String(i),
			parentId: parentId,
			name: '',
			image: '',
			thumbnail: '',
			emoji: '',
			border: '1px solid rgba(128, 128, 128, 0.15)',
			description: '',
			tags: [],
			position: i,
			active: false,
		});
	}

	return arr;
};

interface PageParams {
	id: string;
	owner: string;
	groupId: string;
}

interface PageProps {
	theme: string;
	overlayContainer: ContainerModel;
	setOverlayContainer: Function;
	savedContainers: ContainerModel[];
	addContainerToSaved: Function;
	deleteAllSavedContainers: Function;
	removeSavedContainer: Function;
	savedUrls: string[];
	addToSavedUrls: Function;
	removeLastSavedUrl: Function;
}

const ContainerDisplayGroupsPage: React.FC<PageProps> = ({
	theme,
	overlayContainer,
	setOverlayContainer,
	savedContainers,
	addContainerToSaved,
	removeSavedContainer,
	savedUrls,
	addToSavedUrls,
	removeLastSavedUrl,
}) => {
	const { userId } = useAuth();
	const { id, owner, groupId } = useParams<PageParams>();
	const { t } = useTranslation();
	const history = useHistory();
	const [isMain, setIsMain] = useState<boolean>(true);
	const [contCollection, setContCollection] = useState<ContainerCollection>();
	const [containers, setContainers] = useState<ContainerModel[]>([]);
	const [groupInfo, setGroupInfo] = useState<GroupInfo>();
	const [displayContainers, setDisplayContainers] = useState<ContainerModel[]>([]);
	const [displayNumber, setDisplayNumber] = useState<number>(60);
	const [selectedId, setSelectedId, selectedIdRef] = useStateRef<string>('');
	const [selectedContainer, setSelectedContainer, selectedContainerRef] = useStateRef<ContainerModel>(null);
	const [moveMode, setMoveMode] = useState<boolean>(false);
	const [moveModeSaved, setMoveModeSaved] = useState<boolean>(false);
	const [savedMoveType, setSavedMoveType] = useState<number>(0);
	const [infiniteDisabled, setInfiniteDisabled] = useState<boolean>(false);
	const [showOverlay, setShowOverlay] = useState<boolean>(false);
	const [showOverlayContainer, setShowOverlayContainer] = useState<boolean>(false);
	const [forceLoad, setForceLoad] = useState<number>(0);
	const [checkErrors, setCheckErrors] = useState<boolean>(true);
	const [shouldCheckLevel, setShouldCheckLevel] = useState<boolean>(false);
	const [accessLevel, setAccessLevel] = useState<number>(0);
	const contentRef = useRef<HTMLIonContentElement>();
	const containerDisplayRef = useRef<HTMLIonRowElement>();
	const page = useRef();
	const shouldLoad = useRef<boolean>(true);
	const [presentActions] = useIonActionSheet();
	const [presentDelete] = useIonActionSheet();
	const [presentToast] = useIonToast();
	const [presentLoading, dismissLoading] = useIonLoading();
	const containersPerScrollLoad = 60;
	//TODO replace with value from database assigned to user
	const maxContPerView = 300;

	const initOverlay = () => {
		if (isMain || !overlayContainer) {
			return {
				id: '',
				parentId: '',
				name: t('menu.appTitle'),
				image: theme === 'light' ? '/assets/logo.svg' : '/assets/logo-dark.svg',
				thumbnail: theme === 'light' ? '/assets/logo.svg' : '/assets/logo-dark.svg',
				emoji: '',
				description: '',
				border: '',
				tags: [],
				position: 99999,
				active: true,
				background: '',
				isTransparent: false,
				isGrayscale: false,
			};
		} else {
			return {
				id: '',
				parentId: '',
				name: `${t('display.overlayOpening')} ${overlayContainer?.name}`,
				image: overlayContainer?.image,
				thumbnail: overlayContainer?.thumbnail,
				emoji: overlayContainer?.emoji,
				description: '',
				border: overlayContainer?.border,
				tags: [],
				position: 99999,
				active: true,
				background: overlayContainer?.background,
				isTransparent: overlayContainer?.isTransparent,
				isGrayscale: overlayContainer?.isGrayscale,
			};
		}
	};

	useIonViewDidEnter(() => {
		const strArr = window.location.href.split('collection/');
		const newUrl = strArr[1];
		addToSavedUrls(newUrl, savedUrls, 2);
	});

	// Handler for the back button that appears on views that are not the main
	const handleBackButton = () => {
		setOverlayContainer(null);
		let url = removeLastSavedUrl(savedUrls, 2);

		if (url) {
			history.push(`/app/groups/${owner}/${groupId}/${url}`);
		} else {
			history.goBack();
		}
	};

	// Reset the loading overlay
	useIonViewWillEnter(() => {
		setShowOverlay(true);
		setShowOverlayContainer(false);

		setTimeout(() => {
			setShowOverlayContainer(true);
		}, 1000);

		setTimeout(() => {
			setShowOverlay(false);
		}, 3000);
	});

	// Reset the view
	useIonViewWillEnter(() => {
		setIsMain(id === 'main');
		setContainers([]);
		setDisplayContainers([]);
		shouldLoad.current = true;
		setDisplayNumber(60);
		setForceLoad(forceLoad + 1);
		setCheckErrors(true);
		setMoveMode(false);
		setMoveModeSaved(false);
		setShouldCheckLevel(false);
		setAccessLevel(0);
	}, [id, userId, owner, groupId, forceLoad, setForceLoad]);

	// Cleanup the view
	useIonViewWillLeave(() => {
		setDisplayContainers([]);
		setContainers([]);
		shouldLoad.current = false;
		setCheckErrors(false);
		setMoveMode(false);
		setMoveModeSaved(false);
		setShouldCheckLevel(false);
		setAccessLevel(0);
	}, [id]);

	// Live data fetch
	useEffect(() => {
		const loadingError = () => {
			presentToast({
				message: t('display.toastLoadError'),
				duration: 1000,
				position: 'bottom',
			});
			history.push(`/app/groups/list`);
		};

		try {
			if (id === undefined || id === 'undefined' || owner === undefined || owner === 'undefined' || groupId === undefined || groupId === 'undefined') {
				loadingError();
			}

			// Group Info
			const groupRef = doc(db, 'users', owner, 'groups', groupId);
			const unsubscribeInfo = onSnapshot(groupRef, (doc) => {
				if (checkErrors) {
					if (!doc.exists()) loadingError();
				}

				setGroupInfo(toGroupInfo(doc));
				setShouldCheckLevel(true);
			});

			// Container collection
			const collectionRef = doc(db, 'users', owner, 'groups', groupId, 'collections', id);
			const unsubscribeCollection = onSnapshot(collectionRef, (doc) => {
				if (checkErrors) {
					if (!doc.exists()) loadingError();
				}

				setContCollection(toContainerCollection(doc));
			});

			// Containers
			const containersRef = collection(db, 'users', owner, 'groups', groupId, 'collections', id, 'containers');
			const contQuery = query(containersRef, orderBy('position', 'desc'), limit(maxContPerView));
			const unsubscribeContainers = onSnapshot(contQuery, ({ docs }) => setContainers(docs.map(toContainer)));

			return () => {
				unsubscribeContainers();
				unsubscribeCollection();
				unsubscribeInfo();
			};
		} catch (e) {
			loadingError();
		}
	}, [owner, groupId, id, userId, t, presentToast, history, forceLoad, checkErrors]);

	// Check, evaluate and set the current user's role in the group
	useEffect(() => {
		const loadingError = () => {
			presentToast({
				message: t('display.toastLoadError'),
				duration: 1000,
				position: 'bottom',
			});
			history.push(`/app/groups/list`);
		};

		if (!shouldCheckLevel) return;

		// 1 - Check if the user is part of the members
		const memberInfo = groupInfo.memberAccess.find((member) => member.id === userId);
		if (!memberInfo) loadingError();

		// 2 - Store the current user's role on the state
		setAccessLevel(memberInfo.level);
	}, [groupInfo, userId, shouldCheckLevel, presentToast, t, history]);

	// Mix the live results with placeholders to create the array of containers which will be displayed
	useEffect(() => {
		const createView = async () => {
			if (shouldLoad.current) {
				await presentLoading({
					message: t('display.loadingCollection'),
					cssClass: 'loading',
				});
			}
			const display = createPlaceholderContainers(contCollection?.parentId, displayNumber);
			containers.forEach((cont) => {
				if (display[cont.position] === undefined) return;
				display[cont.position] = cont;
			});
			setDisplayContainers(display);
			await dismissLoading();
		};

		createView();
	}, [contCollection, containers, dismissLoading, displayNumber, presentLoading, t]);

	// Check if the current containers fill the view. If not, load more.
	useEffect(() => {
		const checkForScroll = async () => {
			const scrollEl = await contentRef?.current?.getScrollElement();
			if (!scrollEl) return;

			const hasScroll = scrollEl.scrollHeight > scrollEl.clientHeight;
			const shouldLoadMore = !hasScroll && containers.length < maxContPerView;
			shouldLoadMore && setDisplayNumber(Math.min(displayNumber + containersPerScrollLoad, maxContPerView));
		};

		!infiniteDisabled && checkForScroll();
	}, [containers, displayNumber, infiniteDisabled]);

	// Handler for the IonInfiniteScroll component, triggers data fetch when the user scrolls down to the bottom.
	const loadData = async (ev: CustomEvent<void>) => {
		setDisplayNumber(Math.min(displayNumber + containersPerScrollLoad, maxContPerView));
		await (ev.target as HTMLIonInfiniteScrollElement).complete();

		if (containers.length >= maxContPerView) {
			setInfiniteDisabled(true);
		}
	};

	const canEdit = () => {
		return accessLevel === 1 || accessLevel === 2;
	};

	// Handler for the options shown when the user long presses a container
	const onLongPress = (e) => {
		const showOptions = () => {
			const actions = {
				viewShortcut: {
					text: t('display.actionLongPress.buttons.view'),
					role: 'destructive',
					data: {
						action: 'viewShortcut',
					},
				},
				openShortcut: {
					text: t('display.actionLongPress.buttons.open'),
					role: 'destructive',
					data: {
						action: 'openShortcut',
					},
				},
				move: {
					text: t('display.actionLongPress.buttons.move'),
					role: 'destructive',
					data: {
						action: 'move',
					},
				},
				save: {
					text: t('display.actionLongPress.buttons.save'),
					role: 'destructive',
					data: {
						action: 'save',
					},
				},
				delete: {
					text: t('display.actionLongPress.buttons.delete'),
					role: 'destructive',
					data: {
						action: 'deleteShortcut',
					},
				},
				view: {
					text: t('display.actionLongPress.buttons.view'),
					role: 'destructive',
					data: {
						action: 'view',
					},
				},
				open: {
					text: t('display.actionLongPress.buttons.open'),
					role: 'destructive',
					data: {
						action: 'open',
					},
				},
				createCollection: {
					text: t('display.actionLongPress.buttons.createCollection'),
					role: 'destructive',
					data: {
						action: 'createCollection',
					},
				},
				edit: {
					text: t('display.actionLongPress.buttons.edit'),
					role: 'destructive',
					data: {
						action: 'edit',
					},
				},
				create: {
					text: t('display.actionLongPress.buttons.create'),
					role: 'destructive',
					data: {
						action: 'create',
					},
				},
			};

			if (selectedContainerRef.current.active) {
				if (selectedContainerRef.current.isShortcut) {
					if (canEdit()) {
						return [actions.viewShortcut, actions.openShortcut, actions.move, actions.save, actions.delete];
					} else {
						return [actions.viewShortcut, actions.openShortcut, actions.save];
					}
				} else {
					const options = [actions.view];

					if (selectedContainerRef.current.isParent) {
						options.push(actions.open);
					} else {
						if (canEdit()) {
							options.push(actions.createCollection);
						}
					}

					return canEdit() ? [...options, actions.edit, actions.move, actions.delete, actions.save] : [...options, actions.save];
				}
			} else {
				return canEdit() ? [actions.create] : [];
			}
		};

		const { dataset } = e.target.closest('.container-col');
		if (!dataset) return;
		setSelectedId(String(dataset.id));
		setSelectedContainer(displayContainers.find((cont) => cont.id === dataset.id));

		setMoveMode(false);

		presentActions({
			header: t('display.actionLongPress.header'),
			cssClass: 'actions',
			buttons: [
				...showOptions(),
				{
					text: t('display.actionLongPress.buttons.cancel'),
					role: 'cancel',
					data: {
						action: 'cancel',
					},
				},
			],
			onDidDismiss: ({ detail }) => {
				if (detail.data?.action === 'view') handleView();
				if (detail.data?.action === 'createCollection') handleCreateCollection();
				if (detail.data?.action === 'open') handleOpen();
				if (detail.data?.action === 'openShortcut') handleOpenShortcut();
				if (detail.data?.action === 'edit') handleEdit();
				if (detail.data?.action === 'create') handleCreate();
				if (detail.data?.action === 'move') handleMove();
				if (detail.data?.action === 'delete') handleDelete();
				if (detail.data?.action === 'deleteShortcut') handleDeleteShortcut();
				if (detail.data?.action === 'save') handleSave();
				if (detail.data?.action === 'viewShortcut') handleViewShortcut();

				if (detail.role === 'cancel' || detail.role === 'backdrop') {
					setSelectedId('');
					setSelectedContainer(null);
				}
			},
		});
	};

	const onClick = (e) => {
		if (moveMode) {
			moveContainer(e);
		} else if (moveModeSaved) {
			switch (savedMoveType) {
				case 1:
					moveSaved(e);
					break;
				case 2:
					copySaved(e);
					break;
				case 3:
					createShortcutSaved(e);
					break;
				default:
					cleanupSaved();
			}
		} else {
			const dataset = e.target.closest('.container')?.dataset;
			if (!dataset) return;

			if (dataset.isParent === true || dataset.isParent === 'true') {
				handleOpen(containers.find((cont) => cont.id === dataset.id));
			}

			if (dataset.isShortcut === true || dataset.isShortcut === 'true') {
				handleOpenShortcut(containers.find((cont) => cont.id === dataset.id));
			}
		}

		return;
	};

	const moveContainer = async (e) => {
		// Get the data about the second container
		const secondSelectedCol = e.target.closest('.container-col');
		if (!secondSelectedCol) return;
		const secondSelectedId = secondSelectedCol.dataset.id;
		const secondSelectedContainer = displayContainers.find((cont) => cont.id === secondSelectedId);

		if (secondSelectedContainer.active) {
			// Handle both active
			const firstPosition = selectedContainer.position;
			const secondPosition = secondSelectedContainer.position;

			// Update both, exchanging the positions
			await Promise.all([
				updateDoc(doc(db, 'users', owner, 'groups', groupId, 'collections', selectedContainer.parentId, 'containers', selectedContainer.id), {
					position: secondPosition,
					lastEdit: Timestamp.now(),
				}),
				updateDoc(
					doc(db, 'users', owner, 'groups', groupId, 'collections', secondSelectedContainer.parentId, 'containers', secondSelectedContainer.id),
					{
						position: firstPosition,
						lastEdit: Timestamp.now(),
					}
				),
			]);
		} else {
			// Only the first is active, set the position to that of the second.
			const contRef = doc(db, 'users', owner, 'groups', groupId, 'collections', selectedContainer.parentId, 'containers', selectedContainer.id);
			await updateDoc(contRef, {
				position: secondSelectedContainer.position,
				lastEdit: Timestamp.now(),
			});
		}

		// Cleanup
		setMoveMode(false);
		setSelectedId('');
		setSelectedContainer(null);
	};

	const handler = useLongPress(
		{ onLongPress, onClick },
		{
			shouldPreventDefault: true,
			delay: 400,
		}
	);

	const handleEdit = () => {
		history.push(`/app/groups/edit/${owner}/${groupId}/${selectedContainerRef.current.parentId}/${selectedIdRef.current}`);
		setSelectedId('');
		setSelectedContainer(null);
	};

	const handleCreate = () => {
		history.push(`/app/groups/create/${owner}/${groupId}/${id}/${selectedContainerRef.current.position}`);
		setSelectedId('');
		setSelectedContainer(null);
	};

	const handleMove = () => {
		setMoveMode(true);
		presentToast({
			message: t('display.toastMove'),
			duration: 1000,
			position: 'bottom',
		});
	};

	const handleDelete = () => {
		const actions = {
			delete: {
				text: t('display.actionDelete.buttons.delete'),
				role: 'destructive',
				data: {
					action: 'delete',
				},
			},
			cancel: {
				text: t('display.actionDelete.buttons.cancel'),
				role: 'cancel',
				data: {
					action: 'cancel',
				},
			},
		};

		presentDelete({
			header: t('display.actionDelete.header'),
			subHeader: t('display.actionDelete.subHeader'),
			cssClass: 'actions',
			buttons: [actions.delete, actions.cancel],
			onDidDismiss: ({ detail }) => {
				if (detail.role === 'destructive') deleteContainer();
			},
		});
	};

	const handleDeleteShortcut = () => {
		const actions = {
			delete: {
				text: t('display.actionDelete.buttons.delete'),
				role: 'destructive',
				data: {
					action: 'delete',
				},
			},
			cancel: {
				text: t('display.actionDelete.buttons.cancel'),
				role: 'cancel',
				data: {
					action: 'cancel',
				},
			},
		};

		presentDelete({
			header: t('display.actionDelete.header'),
			cssClass: 'actions',
			buttons: [actions.delete, actions.cancel],
			onDidDismiss: ({ detail }) => {
				if (detail.role === 'destructive') deleteShortcut();
			},
		});
	};

	const deleteContainer = async () => {
		await presentLoading({
			message: t('display.loadingDeleteMsg'),
		});

		const batch = writeBatch(db);

		const idArr = selectedContainerRef.current.parentId.split('-');
		const parentId = idArr[idArr.length - 1];
		const collectionId = `${parentId}-${selectedContainerRef.current.id}`;

		batch.delete(doc(db, 'users', owner, 'groups', groupId, 'collections', id, 'containers', selectedContainerRef.current.id));

		if (selectedContainerRef.current.isParent) batch.delete(doc(db, 'users', owner, 'groups', groupId, 'collections', collectionId));

		await batch.commit();

		await dismissLoading();
	};

	const deleteShortcut = async () => {
		await presentLoading({
			message: t('display.loadingDeleteMsg'),
		});

		await deleteDoc(doc(db, 'users', owner, 'groups', groupId, 'collections', id, 'containers', selectedContainerRef.current.id));

		await dismissLoading();
	};

	const handleView = () => {
		history.push(`/app/groups/view/${owner}/${groupId}/${selectedContainerRef.current.parentId}/${selectedContainerRef.current.id}`);
		setSelectedId('');
		setSelectedContainer(null);
	};

	const handleViewShortcut = () => {
		history.push(`/app/groups/view/${owner}/${groupId}/${selectedContainerRef.current.shortcut.parentId}/${selectedContainerRef.current.shortcut.id}`);
		setSelectedId('');
		setSelectedContainer(null);
	};

	const handleCreateCollection = async () => {
		await presentLoading({
			message: t('display.loadingCreateCollection'),
		});

		setOverlayContainer(selectedContainerRef.current);

		const idArr = selectedContainerRef.current.parentId.split('-');
		const parentId = idArr[idArr.length - 1];
		const collectionId = `${parentId}-${selectedContainerRef.current.id}`;

		await Promise.all([
			setDoc(doc(db, 'users', owner, 'groups', groupId, 'collections', collectionId), {
				parentId: id,
				displayName: selectedContainerRef.current.name,
				numChildren: 0,
				childCollections: [],
			}),
			updateDoc(doc(db, 'users', owner, 'groups', groupId, 'collections', id, 'containers', selectedContainerRef.current.id), {
				isParent: true,
				lastEdit: Timestamp.now(),
				childCollection: collectionId,
			}),
		]);

		await dismissLoading();
		history.push(`/app/groups/collection/${owner}/${groupId}/${collectionId}`);
		setSelectedId('');
		setSelectedContainer(null);
	};

	const handleOpen = async (container: ContainerModel = null) => {
		await setOverlayContainer(container);
		initOverlay();
		history.push(
			`/app/groups/collection/${owner}/${groupId}/${
				container.childCollection === null ? selectedContainerRef.current.childCollection : container.childCollection
			}`
		);
		setSelectedId('');
		setSelectedContainer(null);
	};

	const handleOpenShortcut = async (container: ContainerModel = null) => {
		await setOverlayContainer(container);
		initOverlay();
		history.push(`/app/groups/collection/${owner}/${groupId}/${container.shortcut.parentId}-${container.shortcut.id}`);
		setSelectedId('');
		setSelectedContainer(null);
	};

	const handleSave = () => {
		const container = selectedContainerRef.current;
		container.origin = { groupId: groupId, owner: owner, type: 2 };

		addContainerToSaved(container);
		setSelectedId('');
		setSelectedContainer(null);
	};

	const containerClass = (containerId) => {
		if (containerId === selectedId) return 'container--selected';
		if (moveMode || moveModeSaved) return 'container--move-to';
		return '';
	};

	const clickSavedContainer = (e) => {
		const containerFromThisGroup = (container: ContainerModel) => {
			return container.origin.type === 2 && container.origin.groupId === groupId && container.origin.owner === owner;
		};

		const createOptions = () => {
			const actions = {
				move: {
					text: t('display.actionClickSaved.buttons.move'),
					role: 'destructive',
					data: {
						action: 'move',
					},
				},
				copy: {
					text: t('display.actionClickSaved.buttons.copy'),
					role: 'destructive',
					data: {
						action: 'copy',
					},
				},
				shortcut: {
					text: t('display.actionClickSaved.buttons.shortcut'),
					role: 'destructive',
					data: {
						action: 'shortcut',
					},
				},
				remove: {
					text: t('display.actionClickSaved.buttons.remove'),
					role: 'destructive',
					data: {
						action: 'remove',
					},
				},
				cancel: {
					text: t('display.actionClickSaved.buttons.cancel'),
					role: 'cancel',
					data: {
						action: 'cancel',
					},
				},
			};
			let options = [];
			const container = selectedContainerRef.current;

			if (canEdit()) {
				if (selectedContainerRef.current.isShortcut) {
					options = containerFromThisGroup(container) ? [actions.move] : [];
				} else {
					options = containerFromThisGroup(container) ? [actions.move, actions.copy] : [actions.copy];
				}
			}

			if (container.isParent && !container.isShortcut && canEdit() && containerFromThisGroup(container)) options.push(actions.shortcut);

			options.push(...[actions.remove, actions.cancel]);

			return options;
		};

		const { dataset } = e.target.closest('.saved-container-col');
		if (!dataset) return;

		const selectedId = String(dataset.id);
		if (!selectedId) return;

		const selectedSavedContainer = savedContainers.find((cont) => cont.id === dataset.id);
		if (!selectedSavedContainer) return;

		setSelectedId(selectedId);
		setSelectedContainer(selectedSavedContainer);

		setMoveModeSaved(false);

		const options = createOptions();

		presentActions({
			header: t('display.actionClickSaved.header'),
			subHeader: `${t('display.actionClickSaved.subHeader')}: ${selectedContainerRef.current.name}`,
			cssClass: 'actions',
			buttons: options,
			onDidDismiss: ({ detail }) => {
				if (detail.data?.action === 'move') handleMoveSaved();
				if (detail.data?.action === 'copy') handleCopySaved();
				if (detail.data?.action === 'shortcut') handleShortcutSaved();
				if (detail.data?.action === 'remove') handleRemoveSaved();

				if (detail.role === 'cancel' || detail.role === 'backdrop') {
					setSelectedId('');
					setSelectedContainer(null);
				}
			},
		});
	};

	const handleMoveSaved = () => {
		setMoveModeSaved(true);
		setSavedMoveType(1);
		presentToast({
			message: t('display.toastMoveSaved'),
			duration: 1000,
			position: 'bottom',
		});
	};

	const getDataForCopy = (e) => {
		// Get data from the container that was picked as the new position
		const { dataset } = e.target.closest('.container-col');
		if (!dataset) return;

		const secondSelectedId = dataset.id;
		const secondSelectedContainer = displayContainers.find((cont) => cont.id === secondSelectedId);

		if (!secondSelectedContainer) return;

		// Create a new object using the data from the saved container
		const newData = {
			...selectedContainerRef.current,
		};

		// Overwrite data that was inherited from the original
		newData.position = secondSelectedContainer.position;
		newData.created = Timestamp.now();
		newData.lastEdit = Timestamp.now();
		newData.parentId = id;

		// Remove the id from the object so firebase can assign a new one automatically
		delete newData.id;

		return newData;
	};

	const moveSaved = async (e) => {
		await presentLoading({
			message: t('display.loadingMoveSaved'),
		});

		// 1 - Delete the old container and update the collection with the new parent if needed
		const idArr = selectedContainerRef.current.parentId.split('-');
		const parentId = idArr[idArr.length - 1];
		const collectionId = `${parentId}-${selectedContainerRef.current.id}`;

		const batch = writeBatch(db);

		batch.delete(
			doc(db, 'users', owner, 'groups', groupId, 'collections', selectedContainerRef.current.parentId, 'containers', selectedContainerRef.current.id)
		);

		const colSnap = await getDoc(doc(db, 'users', owner, 'groups', groupId, 'collections', collectionId));

		if (colSnap.exists() && selectedContainerRef.current.isParent && !selectedContainerRef.current.isShortcut) {
			batch.update(doc(db, 'users', owner, 'groups', groupId, 'collections', collectionId), {
				parentId: id,
			});
		}

		await batch.commit();

		// 2 - Create a copy
		const newData = getDataForCopy(e);

		await addDoc(collection(db, 'users', owner, 'groups', groupId, 'collections', id, 'containers'), newData);

		// 3 - Remove it from savedContainers
		removeSavedContainer(selectedContainerRef.current.id);

		// 4 - Cleanup
		cleanupSaved();
		await dismissLoading();
	};

	const handleCopySaved = () => {
		setMoveModeSaved(true);
		setSavedMoveType(2);
		presentToast({
			message: selectedContainerRef.current.isParent ? t('display.toastCopySavedParent') : t('display.toastCopySaved'),
			duration: 2500,
			position: 'bottom',
		});
	};

	const copySaved = async (e) => {
		await presentLoading({
			message: t('display.loadingCopySaved'),
		});

		// Remove previous container in the position, in case it exists
		await handleRemovingPreviousContainer(e);

		// Create a new object using the data from the saved container
		const newData = getDataForCopy(e);
		delete newData.isParent;

		// Save the container
		await addDoc(collection(db, 'users', owner, 'groups', groupId, 'collections', id, 'containers'), newData);

		// Cleanup
		cleanupSaved();
		await dismissLoading();
	};

	const handleShortcutSaved = () => {
		setMoveModeSaved(true);
		setSavedMoveType(3);
		presentToast({
			message: t('display.toastShortcutSaved'),
			duration: 1000,
			position: 'bottom',
		});
	};

	const createShortcutSaved = async (e) => {
		// 1 - Get the position from the new container
		const { dataset } = e.target.closest('.container-col');
		if (!dataset) return;

		const secondSelectedId = dataset.id;
		const secondSelectedContainer = displayContainers.find((cont) => cont.id === secondSelectedId);

		if (!secondSelectedContainer) return;

		await presentLoading({
			message: t('display.loadingCreateShortcutSaved'),
		});

		// 2 - Remove previous container in the position, in case it exists
		await handleRemovingPreviousContainer(e);

		// 3 - Take data from the saved container, add the shortcut flag and data to it
		const newData = {
			...selectedContainerRef.current,
			isShortcut: true,
			shortcut: { id: selectedContainerRef.current.id, parentId: selectedContainerRef.current.parentId },
		};

		// 3.1 - Overwrite data that was inherited from the original
		newData.position = secondSelectedContainer.position;
		newData.parentId = id;
		// 3.2 - Remove the id from the object so firebase can assign a new one automatically
		delete newData.id;
		delete newData.isParent;

		// 4 - Save the container
		await addDoc(collection(db, 'users', owner, 'groups', groupId, 'collections', id, 'containers'), newData);

		// 5 - Cleanup
		cleanupSaved();
		await dismissLoading();
	};

	const handleRemoveSaved = async () => {
		removeSavedContainer(selectedContainerRef.current.id);
		setSelectedId('');
		setSelectedContainer(null);
	};

	const handleRemovingPreviousContainer = async (e) => {
		// 1 - Find if the new container is active
		const { dataset } = e.target.closest('.container-col');
		if (!dataset) return;

		const secondSelectedId = dataset.id;
		const secondSelectedContainer = displayContainers.find((cont) => cont.id === secondSelectedId);

		if (!secondSelectedContainer.active) return;

		// 2 - Delete it from the firebase data
		await Promise.all([
			deleteDoc(doc(db, 'users', owner, 'groups', groupId, 'collections', secondSelectedContainer.parentId, 'containers', secondSelectedContainer.id)),
		]);
	};

	// Clean everything set by the saved containers functions
	const cleanupSaved = () => {
		setSavedMoveType(0);
		setMoveModeSaved(false);
		setSelectedContainer(null);
		setSelectedId('');
	};

	const openGroupDetails = () => {
		history.push(`/app/groups/details/${owner}/${groupId}/`);
	};

	return (
		<IonPage ref={page}>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot='start'>
						<IonMenuButton />
						{!isMain && (
							<IonButton onClick={handleBackButton}>
								<IonIcon slot='icon-only' icon={backIcon} />
							</IonButton>
						)}
					</IonButtons>
					<IonAvatar slot='start' className='group-avatar'>
						<img alt='Avatar' src={groupInfo?.image} />
					</IonAvatar>
					<IonTitle className='group-title' onClick={openGroupDetails}>
						{isMain ? groupInfo?.displayName : `${groupInfo?.displayName} - ${contCollection?.displayName}`}
					</IonTitle>
				</IonToolbar>
				<SavedContainers containers={savedContainers} clickSavedContainer={clickSavedContainer} />
			</IonHeader>

			<IonContent className='ion-padding' ref={contentRef}>
				<IonGrid fixed={true}>
					<IonRow ref={containerDisplayRef}>
						{displayContainers?.map((cont) => (
							<IonCol className={`container-col ${containerClass(cont.id)}`} key={cont.id} data-id={cont.id}>
								<Container data={cont} handler={handler}></Container>
							</IonCol>
						))}
					</IonRow>
				</IonGrid>
				<IonInfiniteScroll onIonInfinite={loadData} threshold='100px' disabled={infiniteDisabled}>
					<IonInfiniteScrollContent loadingSpinner='circular' loadingText={t('display.scrollLoadingText')}></IonInfiniteScrollContent>
				</IonInfiniteScroll>
				<IonModal isOpen={showOverlay} className='loading-collection-overlay' keepContentsMounted={false}>
					<div className={`overlay-container ${showOverlayContainer ? '' : 'hide-image'}`}>
						<Container data={initOverlay()}></Container>
					</div>
				</IonModal>
			</IonContent>
		</IonPage>
	);
};

export default ContainerDisplayGroupsPage;
