import React from 'react';
import { IonIcon, IonLabel, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs } from '@ionic/react';
import { hammerOutline as appIcon, personOutline as userIcon } from 'ionicons/icons';
import { Redirect, Route } from 'react-router-dom';
import SettingsAppPage from './SettingsAppPage';
import SettingsProfilePage from './SettingsProfilePage';
import { useTranslation } from 'react-i18next';
import { UserProfile } from '../models';

interface settingsProps {
	theme: string;
	setTheme: Function;
	profile: UserProfile;
	setProfile: Function;
}

const SettingsPage: React.FC<settingsProps> = ({ theme, setTheme, profile, setProfile }) => {
	const { t } = useTranslation();

	return (
		<IonTabs>
			<IonRouterOutlet>
				<Route exact path='/app/config/profile'>
					<SettingsProfilePage profile={profile} setProfile={setProfile} />
				</Route>
				<Route exact path='/app/config/app'>
					<SettingsAppPage theme={theme} setTheme={setTheme} />
				</Route>
				<Redirect exact from='/app/config' to='/app/config/app' />
			</IonRouterOutlet>
			<IonTabBar slot='bottom'>
				<IonTabButton tab='home' href='/app/config/app'>
					<IonIcon icon={appIcon} />
					<IonLabel>{t('settings.tabs.app')}</IonLabel>
				</IonTabButton>
				<IonTabButton tab='settings' href='/app/config/profile'>
					<IonIcon icon={userIcon} />
					<IonLabel>{t('settings.tabs.profile')}</IonLabel>
				</IonTabButton>
			</IonTabBar>
		</IonTabs>
	);
};

export default SettingsPage;
