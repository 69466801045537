import { DocumentSnapshot, Timestamp } from 'firebase/firestore';

// User information
export interface UserSettings {
	language?: string;
	theme?: string;
}

export interface UserProfile {
	displayName?: string;
	image?: string;
	fullImage?: string;
	id?: string;
	lastUpdate?: Timestamp;
	bio?: string;
}

export interface Auth {
	loggedIn: boolean;
	userId?: string;
	userSettings?: UserSettings;
	userProfile?: UserProfile;
}

export interface AuthInit {
	loading: boolean;
	auth?: Auth;
}

// Menu
export interface MenuPage {
	title: string;
	url: string;
	icon: string;
}

export interface WebBrowserMenuPage {
	title: string;
	url: string;
}

export const toWebBrowserMenuPageArr = (docSnapArr: DocumentSnapshot[]): WebBrowserMenuPage[] => {
	return docSnapArr.map(function (doc) {
		return { title: doc.id, ...doc.data() } as WebBrowserMenuPage;
	});
};

// Containers
interface ContainerRoles {
	owner: string;
	viewers?: string[];
	editors?: string[];
}

interface ContainerShortcut {
	id: string;
	parentId: string;
}

interface ContainerOrigin {
	type: number;
	owner?: string;
	groupId?: string;
}

export interface ContainerModel {
	id?: string;
	parentId: string;
	name: string;
	image: string;
	thumbnail: string;
	emoji: string;
	border: string;
	description: string;
	tags: string[];
	position: number;
	active: boolean;
	created?: Timestamp;
	lastEdit?: Timestamp;
	background?: string;
	isParent?: boolean;
	isGrayscale?: boolean;
	isTransparent?: boolean;
	childCollection?: string;
	searchName?: string[];
	roles?: ContainerRoles;
	isShortcut?: boolean;
	shortcut?: ContainerShortcut;
	origin?: ContainerOrigin;
}

export const toContainer = (docSnap: DocumentSnapshot): ContainerModel => {
	return { id: docSnap.id, ...docSnap.data() } as ContainerModel;
};

export const toContainerArr = (docSnapArr: DocumentSnapshot[]): ContainerModel[] => {
	return docSnapArr.map(function (doc) {
		return { id: doc.id, ...doc.data() } as ContainerModel;
	});
};

// Collections
export interface ContainerCollection {
	id: string;
	parentId?: string;
	displayName?: string;
	numChildren: number;
	childCollections?: string[];
}

export const toContainerCollection = (docSnap: DocumentSnapshot): ContainerCollection => {
	return { id: docSnap.id, ...docSnap.data() } as ContainerCollection;
};

export interface GroupInfo {
	id?: string;
	displayName?: string;
	image?: string;
	fullImage?: string;
	lastUpdate?: Timestamp;
	bio?: string;
	members?: string[];
	memberAccess?: GroupMemberAccess[];
	ownerId?: string;
}

export interface GroupMemberAccess {
	id: string;
	level: number;
}

export const toGroupInfoArr = (docSnapArr: DocumentSnapshot[]): GroupInfo[] => {
	return docSnapArr.map(function (doc) {
		return { id: doc.id, ...doc.data() } as GroupInfo;
	});
};

export const toGroupInfo = (docSnap: DocumentSnapshot): GroupInfo => {
	return { id: docSnap.id, ...docSnap.data() } as GroupInfo;
};

export interface ProfileDisplay {
	displayName?: string;
	image?: string;
	shouldAvoidLoad?: boolean;
}
