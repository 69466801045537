import React, { useEffect, useRef, useState } from 'react';
import {
	IonButton,
	IonButtons,
	IonContent,
	IonFooter,
	IonHeader,
	IonIcon,
	IonItem,
	IonLabel,
	IonLoading,
	IonMenuButton,
	IonPage,
	IonSelect,
	IonSelectOption,
	IonTitle,
	IonToast,
	IonToggle,
	IonToolbar,
	useIonLoading,
} from '@ionic/react';
import { earthOutline as languageIcon, moon as darkThemeIcon, lockClosed as sendMailIcon } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../auth';
import { auth, db } from '../firebase';
import { doc, updateDoc } from 'firebase/firestore';
import { sendPasswordResetEmail, signOut } from 'firebase/auth';
import { pageTitles } from './helpers/pageTitles';

interface settingsProps {
	theme: string;
	setTheme: Function;
}

const SettingsAppPage: React.FC<settingsProps> = ({ theme, setTheme }) => {
	const { userId, userSettings } = useAuth();
	const themeToggle = useRef<HTMLIonToggleElement>();
	const { t, i18n } = useTranslation();
	const [presentLoading, dismissLoading] = useIonLoading();
	const [language, setLanguage] = useState<string>();
	const [showToast, setShowToast] = useState(false);
	const [toastMsg, setToastMsg] = useState('');
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setLanguage(userSettings.language);
		setTheme(userSettings.theme);

		themeToggle.current.checked = userSettings.theme === 'dark';
	}, [userSettings, setTheme]);

	useEffect(() => {
		const toggle = themeToggle.current;
		const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');

		const checkToggle = (shouldCheck) => {
			toggle.checked = shouldCheck;
		};

		const mediaListener = (e) => {
			checkToggle(e.matches);
		};

		const toggleListenerCallback = (ev) => {
			if (ev.detail.checked) {
				document.body.setAttribute('color-theme', 'dark');
				setTheme('dark');
			} else {
				document.body.setAttribute('color-theme', 'light');
				setTheme('light');
			}
		};

		prefersDark.addEventListener('change', mediaListener);
		toggle.addEventListener('ionChange', toggleListenerCallback);

		return () => {
			prefersDark.removeEventListener('change', mediaListener);
			toggle.removeEventListener('ionChange', toggleListenerCallback);
		};
	}, [themeToggle, userSettings, setTheme]);

	const ERR_MSGS = {
		'auth/too-many-requests': t('settings.app.errorMsg.tooManyRequests'),
		'auth/invalid-email': t('settings.app.errorMsg.invalidEmail'),
		'auth/user-not-found': t('settings.app.errorMsg.userNotFound'),
	};

	const handleLanguage = async (e) => {
		const selected = e.detail.value;
		await i18n.changeLanguage(selected);
		setLanguage(selected);
		document.title = pageTitles[selected];
	};

	const handleLogout = async () => {
		await signOut(auth);
	};

	const handleApply = async () => {
		await presentLoading({
			message: t('settings.app.loadingApply'),
		});

		const settingsRef = doc(db, 'users', userId, 'settings', 'app');
		await updateDoc(settingsRef, { theme, language });

		await dismissLoading();
	};

	const handleRecovery = async () => {
		try {
			setLoading(true);
			await sendPasswordResetEmail(auth, auth.currentUser.email);
			setLoading(false);

			setToastMsg(t('settings.app.toastMailSent'));
			setShowToast(true);
		} catch (e) {
			console.log(e.code);
			setLoading(false);
			setToastMsg(ERR_MSGS[e.code.toString()] || t('settings.app.errorMsg.fallback'));
			setShowToast(true);
		}
	};

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot='start'>
						<IonMenuButton />
					</IonButtons>
					<IonTitle>{t('settings.app.title')}</IonTitle>
				</IonToolbar>
			</IonHeader>

			<IonContent className='ion-padding'>
				<IonItem lines='full'>
					<IonIcon slot='start' icon={languageIcon} />
					<IonLabel>{t('settings.app.labelLanguage')}</IonLabel>
					<IonSelect interface='popover' value={language} onIonChange={handleLanguage}>
						<IonSelectOption value='es'>{t('settings.app.optionEs')}</IonSelectOption>
						<IonSelectOption value='en'>{t('settings.app.optionEn')}</IonSelectOption>
					</IonSelect>
				</IonItem>
				<IonItem lines='full'>
					<IonIcon slot='start' icon={darkThemeIcon} />
					<IonLabel>{t('settings.app.labelTheme')}</IonLabel>
					<IonToggle id='themeToggle' slot='end' color='primary' ref={themeToggle}></IonToggle>
				</IonItem>
				<IonItem lines='full'>
					<IonIcon slot='start' icon={sendMailIcon} />
					<IonLabel>{t('settings.app.labelSendPassMail')}</IonLabel>
					<IonButton slot='end' onClick={handleRecovery}>
						{t('settings.app.btnSendPassMail')}
					</IonButton>
				</IonItem>
				<IonToast isOpen={showToast} onDidDismiss={() => setShowToast(false)} message={toastMsg} duration={5000} position='bottom'></IonToast>
				<IonLoading isOpen={loading} />
			</IonContent>
			<IonFooter>
				<IonToolbar style={{ padding: '10px' }}>
					<IonButton color='primary' expand='block' onClick={handleApply} style={{ marginBottom: '20px' }}>
						{t('settings.app.btnApply')}
					</IonButton>
					<IonButton color='medium' expand='block' onClick={handleLogout}>
						{t('settings.app.btnLogout')}
					</IonButton>
				</IonToolbar>
			</IonFooter>
		</IonPage>
	);
};

export default SettingsAppPage;
