import { cogOutline as configIcon, homeOutline as homeIcon, peopleOutline as groupIcon } from 'ionicons/icons';
import { MenuPage } from '../models';

export const menuPages: MenuPage[] = [
	{
		title: 'main',
		url: '/app/collection/main',
		icon: homeIcon,
	},
	{
		title: 'config',
		url: '/app/config',
		icon: configIcon,
	},
	{
		title: 'groups',
		url: '/app/groups/list',
		icon: groupIcon,
	},
];
