import React, { useEffect, useRef, useState } from 'react';
import {
	IonButton,
	IonButtons,
	IonContent,
	IonFab,
	IonFabButton,
	IonFabList,
	IonHeader,
	IonIcon,
	IonInput,
	IonItem,
	IonLabel,
	IonList,
	IonMenuButton,
	IonPage,
	IonRange,
	IonSelect,
	IonSelectOption,
	IonTextarea,
	IonTitle,
	IonToggle,
	IonToolbar,
	isPlatform,
	useIonLoading,
	useIonViewWillEnter,
	useIonViewWillLeave,
} from '@ionic/react';
import { useHistory, useParams } from 'react-router';
import { createPopup } from '@picmo/popup-picker';
import { NativeRenderer } from 'picmo';
import { Locale } from 'emojibase';
import { picmoTranslation } from './helpers/picmoTranslation';
import { useTranslation } from 'react-i18next';
import { RgbStringColorPicker } from 'react-colorful';
import { doc, getDoc, Timestamp, updateDoc } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import ReactCrop, { centerCrop, Crop, makeAspectCrop, PixelCrop } from 'react-image-crop';
import { chevronBackCircle as backIcon, eyeOutline as showIcon } from 'ionicons/icons';
import { ContainerModel, toContainer } from '../models';
import Container from '../components/Container';
import { useAuth } from '../auth';
import { db, storage } from '../firebase';
import { imgPreview } from './helpers/imgPreview';
import { compressToJPEG } from './helpers/compressImage';
import './ContainerGroupsEditPage.css';
import 'react-image-crop/dist/ReactCrop.css';

interface PageParams {
	id: string;
	parentId: string;
	owner: string;
	groupId: string;
}

const ContainerGroupsEditPage: React.FC = () => {
	const { id, parentId, groupId, owner } = useParams<PageParams>();
	const { t, i18n } = useTranslation();
	const { userId } = useAuth();
	const history = useHistory();
	const imgRef = useRef<HTMLImageElement>();
	const fileInputRef = useRef<HTMLInputElement>();
	const emojiInputRef = useRef<HTMLIonButtonElement>();
	const tagListRef = useRef<HTMLUListElement>();
	const [container, setContainer] = useState<ContainerModel>();
	const [name, setName] = useState<string>('');
	const [emoji, setEmoji] = useState<string>('');
	const [imageURL, setImageURL] = useState<string>('/assets/images/placeholder.png');
	const [thumbnailURL, setThumbnailURL] = useState<string>('');
	const [isNewImage, setIsNewImage] = useState<boolean>(false);
	const [useBorder, setUseBorder] = useState<boolean>();
	const [description, setDescription] = useState<string>('');
	const [tags, setTags] = useState<string[]>([]);
	const [border, setBorder] = useState<string>('');
	const [backgroundColor, setBackgroundColor] = useState<string>('');
	const [borderColor, setBorderColor] = useState<string>('');
	const [borderWidth, setBorderWidth] = useState<string>('');
	const [borderStyle, setBorderStyle] = useState<string>('');
	const [isGrayscale, setIsGrayscale] = useState<boolean>(false);
	const [isTransparent, setIsTransparent] = useState<boolean>(false);
	const [crop, setCrop] = useState<Crop>();
	const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
	const [presentLoading, dismissLoading] = useIonLoading();
	const [useCrop, setUseCrop] = useState<boolean>(true);

	const init = () => {
		setName('');
		setEmoji('');
		setImageURL('/assets/images/placeholder.png');
		setThumbnailURL('');
		setDescription('');
		setTags([]);
		setBorder('');
		setBorderWidth('1px');
		setBorderStyle('solid');
		setBorderColor('rgba(128, 128, 128, 0.15)');
		setBackgroundColor(`rgba(0, 0, 0, 0)`);
		setIsNewImage(false);
		setUseBorder(false);
		setIsGrayscale(false);
		setIsTransparent(false);
		setUseCrop(true);
		setContainer(null);
	};

	// Data initialization and fetch
	useIonViewWillEnter(() => {
		const getData = async () => {
			const docRef = doc(db, 'users', owner, 'groups', groupId, 'collections', parentId, 'containers', id);
			const docSnap = await getDoc(docRef);

			if (!docSnap.exists()) throw new Error(`Couldn't find an entry with the given ID (${id}), please try again.`);

			setContainer(toContainer(docSnap));
		};

		init();

		getData();
	}, [userId, parentId, id, owner, groupId]);

	useIonViewWillLeave(() => {
		init();
	});

	// Data setter on live changes
	useEffect(() => {
		setName(container?.name);
		setEmoji(container?.emoji);
		setImageURL(container?.image || '/assets/images/placeholder.png');
		setThumbnailURL(container?.thumbnail);
		setDescription(container?.description);
		setTags(container?.tags);
		setBorder(container?.border);
		setBackgroundColor(container?.background);
		setIsTransparent(container?.isTransparent || false);
		setIsGrayscale(container?.isGrayscale || false);
		setIsNewImage(false);

		const borderArr = container?.border?.split(' ');
		if (borderArr) {
			setBorderWidth(borderArr[0]);
			setBorderStyle(borderArr[1]);
			setBorderColor(borderArr[2]);

			setUseBorder(borderArr[1] !== 'none');
		}
	}, [container]);

	// Border state update
	useEffect(() => {
		setBorder(`${borderWidth} ${borderStyle} ${borderColor}`);
	}, [borderColor, borderStyle, borderWidth]);

	// Border cleanup on disable
	useEffect(() => {
		if (!useBorder) {
			setBorderWidth('1px');
			setBorderStyle('none');
			setBorderColor('rgba(128, 128, 128, 0.15)');
		}
	}, [useBorder]);

	// Cleanup for the createObjectURL
	useEffect(() => {
		return () => {
			if (imageURL.startsWith('blob:')) {
				URL.revokeObjectURL(imageURL);
			}
		};
	}, [imageURL]);

	// Emoji selector
	useEffect(() => {
		const handleSelectEmoji = (event) => {
			setEmoji(event.emoji);
			picker.close();
		};

		const emojiBtn = emojiInputRef.current;

		const picker = createPopup(
			{
				renderer: new NativeRenderer(),
				emojisPerRow: 6,
				visibleRows: 5,
				showPreview: false,
				locale: i18n.language as Locale,
				i18n: picmoTranslation[i18n.language],
			},
			{
				triggerElement: emojiBtn,
				referenceElement: emojiBtn,
				hideOnEmojiSelect: true,
				onPositionLost: 'close',
			}
		);

		emojiBtn.addEventListener('click', () => {
			picker.open();
		});

		picker.addEventListener('emoji:select', handleSelectEmoji);

		return () => {
			picker.removeEventListener('emoji:select', handleSelectEmoji);
			emojiBtn.removeEventListener('click', () => picker.open());
		};
	}, [i18n.language]);

	// Removing tags
	useEffect(() => {
		const handleRemoveTag = (e) => {
			if (!e.target.classList.contains('tag')) return;
			setTags(tags.filter((tag) => tag !== e.target.dataset.tag));
		};

		const ref = tagListRef.current;

		ref.addEventListener('click', handleRemoveTag);

		return () => ref.removeEventListener('click', handleRemoveTag);
	}, [tagListRef, tags]);

	useEffect(() => {
		const generateThumbnail = async () => {
			if (isNewImage && completedCrop?.width && completedCrop?.height && imgRef.current) {
				const thumb = useCrop ? await imgPreview(imgRef.current, completedCrop) : imageURL;
				setThumbnailURL(thumb);
			}
		};

		generateThumbnail();
	}, [completedCrop, imageURL, isNewImage, useCrop]);

	const onImageLoad = (e) => {
		const { naturalWidth: width, naturalHeight: height } = e.currentTarget;

		const crop = centerCrop(
			makeAspectCrop(
				{
					unit: '%',
					width: 90,
				},
				1,
				width,
				height
			),
			width,
			height
		);

		setCrop(crop);
	};

	// Saving tags
	const handleTagsChange = (e) => {
		if (e.key === 'Enter') {
			setTags([...tags, e.target.value.toLowerCase()]);
			e.target.value = '';
		}
	};

	// Handling image URLs
	const handleFileChange = async (event) => {
		if (event.target.files.length === 0) return;

		setCrop(undefined);
		const file = event.target.files.item(0);
		const compressedFile = await compressToJPEG(file, 200);
		const imageURL = URL.createObjectURL(compressedFile);
		setImageURL(imageURL);
		setIsNewImage(true);
	};

	// Handle click to upload/take picture
	const handleImageClick = async () => {
		if (isPlatform('capacitor')) {
			fileInputRef.current.click();
		} else {
			fileInputRef.current.click();
		}
	};

	const uploadImages = async (userId, parentId, containerId, imageUrl, thumbnailUrl): Promise<string[]> => {
		const [imageResponse, thumbResponse] = await Promise.all([fetch(imageUrl), fetch(thumbnailUrl)]);
		const [imageBlob, thumbBlob] = await Promise.all([imageResponse.blob(), thumbResponse.blob()]);

		const pictureRef = ref(storage, `/users/${owner}/groups/${groupId}/collections/${parentId}/containers/${containerId}/image`);
		const thumbRef = ref(storage, `/users/${owner}/groups/${groupId}/collections/${parentId}/containers/${containerId}/thumbnail`);

		const [imageTask, thumbTask] = await Promise.all([uploadBytes(pictureRef, imageBlob), uploadBytes(thumbRef, thumbBlob)]);

		return await Promise.all([getDownloadURL(imageTask.ref), getDownloadURL(thumbTask.ref)]);
	};

	// Handle save
	const handleSave = async () => {
		await presentLoading({
			message: !isNewImage ? t('edit.loadingUploadMsg') : t('edit.loadingUploadMsgImg'),
		});
		let newData;
		const searchArr = name.toLowerCase().split(' ');

		if (isNewImage) {
			const [newImageUrl, newThumbUrl] = await uploadImages(userId, parentId, id, imageURL, thumbnailURL);

			newData = {
				id,
				parentId,
				name,
				emoji,
				border,
				description,
				tags,
				position: container.position,
				active: true,
				lastEdit: Timestamp.now(),
				background: backgroundColor,
				isGrayscale,
				isTransparent,
				image: newImageUrl,
				thumbnail: newThumbUrl,
				searchName: [...searchArr, name.toLowerCase()],
			};
		} else {
			newData = {
				id,
				parentId,
				name,
				emoji,
				border,
				description,
				tags,
				position: container.position,
				active: true,
				lastEdit: Timestamp.now(),
				background: backgroundColor,
				isGrayscale,
				isTransparent,
				searchName: [...searchArr, name.toLowerCase()],
			};
		}

		const containerRef = doc(db, 'users', owner, 'groups', groupId, 'collections', parentId, 'containers', id);

		if (container.isParent) {
			const childColRef = doc(db, 'users', owner, 'groups', groupId, 'collections', container.childCollection);
			await Promise.all([updateDoc(containerRef, newData), updateDoc(childColRef, { displayName: name })]);
		} else {
			await updateDoc(containerRef, newData);
		}

		await dismissLoading();

		history.push(`/app/groups/collection/${owner}/${groupId}/${parentId}`);
	};

	const handleBackButton = () => {
		history.push(`/app/groups/collection/${owner}/${groupId}/${parentId}`);
	};

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot='start'>
						<IonMenuButton />
						<IonButton onClick={handleBackButton}>
							<IonIcon slot='icon-only' icon={backIcon} />
						</IonButton>
					</IonButtons>
					<IonTitle>{t('edit.title')}</IonTitle>
				</IonToolbar>
			</IonHeader>

			<IonContent className='ion-padding'>
				<IonFab slot='fixed' vertical='top' horizontal='end' edge activated>
					<IonFabButton>
						<IonIcon icon={showIcon} />
					</IonFabButton>
					<IonFabList side='start'>
						<Container
							data={{
								id: '',
								parentId: '',
								name,
								image: imageURL,
								thumbnail: thumbnailURL,
								emoji,
								description,
								border,
								tags,
								position: 99999,
								active: true,
								background: backgroundColor,
								isTransparent,
								isGrayscale,
							}}
						></Container>
					</IonFabList>
				</IonFab>
				<IonList>
					<IonItem>
						<IonLabel position='stacked'>{t('edit.labelImg')}</IonLabel> <br />
						<input type='file' accept='image/*' alt={t('edit.labelImg')} onChange={handleFileChange} ref={fileInputRef} hidden />
						{isNewImage && (
							<ReactCrop
								crop={crop}
								onChange={(c) => setCrop(c)}
								aspect={1}
								className='container-crop'
								onComplete={(c) => setCompletedCrop(c)}
								disabled={!isNewImage}
								ruleOfThirds={true}
							>
								<img
									src={imageURL}
									alt=''
									onClick={handleImageClick}
									style={{ cursor: 'pointer' }}
									className='container-uploaded-image'
									onLoad={onImageLoad}
									ref={imgRef}
								/>
							</ReactCrop>
						)}
						{!isNewImage && (
							<img
								src={imageURL}
								alt=''
								onClick={handleImageClick}
								style={{ cursor: 'pointer' }}
								className='container-uploaded-image'
								onLoad={onImageLoad}
								ref={imgRef}
							/>
						)}
					</IonItem>
					{isNewImage && (
						<IonItem>
							<IonLabel>{t('edit.labelUseCrop')}</IonLabel>
							<IonToggle slot='end' checked={useCrop} onClick={() => setUseCrop(!useCrop)}></IonToggle>
						</IonItem>
					)}
					<IonItem>
						<IonLabel>{t('edit.labelBackgroundColor')}</IonLabel>
						<RgbStringColorPicker color={backgroundColor} onChange={setBackgroundColor} />
					</IonItem>
					<IonItem>
						<IonLabel position='stacked'>{t('edit.labelName')}</IonLabel>
						<IonInput value={name} onIonChange={(e) => setName(e.detail.value)} />
						<IonButton fill='outline' slot='end' ref={emojiInputRef} className='emoji'>
							{emoji ? emoji : '➕'}
						</IonButton>
					</IonItem>
					<IonItem>
						<IonLabel position='stacked'>{t('edit.labelDescription')}</IonLabel>
						<IonTextarea value={description} autoGrow={true} rows={4} onIonChange={(e) => setDescription(e.detail.value)} />
					</IonItem>
					<IonItem>
						<IonLabel position='stacked'>{t('edit.labelTags')}</IonLabel>
						<IonInput onKeyUp={handleTagsChange} placeholder={t('edit.inputTagsPlaceholder')} />
					</IonItem>
					<ul className='tags' ref={tagListRef}>
						{tags?.map((tag) => (
							<li key={tag} slot='start' className='tag' data-tag={tag}>
								{tag}
							</li>
						))}
					</ul>
					<IonItem>
						<IonLabel>{t('edit.labelToggleBorder')}</IonLabel>
						<IonToggle slot='end' checked={useBorder} onClick={() => setUseBorder(!useBorder)}></IonToggle>
					</IonItem>
					{useBorder && (
						<IonItem>
							<IonLabel>{t('edit.labelGrayscale')}</IonLabel>
							<IonToggle slot='end' checked={isGrayscale} onClick={() => setIsGrayscale(!isGrayscale)}></IonToggle>
						</IonItem>
					)}
					{useBorder && (
						<IonItem>
							<IonLabel>{t('edit.labelTransparent')}</IonLabel>
							<IonToggle slot='end' checked={isTransparent} onClick={() => setIsTransparent(!isTransparent)}></IonToggle>
						</IonItem>
					)}
					{useBorder && (
						<IonItem>
							<IonLabel>{t('edit.labelBorderStyle')}</IonLabel>
							<IonSelect
								slot='end'
								interface='popover'
								placeholder={t('edit.selectBorderStylePlaceholder')}
								onIonChange={({ detail }) => setBorderStyle(detail.value)}
								value={borderStyle}
							>
								<IonSelectOption value='none'>{t('edit.optionsBorderStyle.none')}</IonSelectOption>
								<IonSelectOption value='solid'>{t('edit.optionsBorderStyle.solid')}</IonSelectOption>
								<IonSelectOption value='dotted'>{t('edit.optionsBorderStyle.dotted')}</IonSelectOption>
								<IonSelectOption value='dashed'>{t('edit.optionsBorderStyle.dashed')}</IonSelectOption>
								<IonSelectOption value='double'>{t('edit.optionsBorderStyle.double')}</IonSelectOption>
								<IonSelectOption value='groove'>{t('edit.optionsBorderStyle.groove')}</IonSelectOption>
								<IonSelectOption value='ridge'>{t('edit.optionsBorderStyle.ridge')}</IonSelectOption>
								<IonSelectOption value='inset'>{t('edit.optionsBorderStyle.inset')}</IonSelectOption>
								<IonSelectOption value='outset'>{t('edit.optionsBorderStyle.outset')}</IonSelectOption>
							</IonSelect>
						</IonItem>
					)}
					{borderStyle !== 'none' && (
						<IonItem>
							<IonLabel>{t('edit.labelBorderSize')}</IonLabel>
							<IonRange onIonChange={({ detail }) => setBorderWidth(`${detail.value}px`)} ticks={true} snaps={true} min={1} max={15}></IonRange>
						</IonItem>
					)}
					{borderStyle !== 'none' && (
						<IonItem>
							<IonLabel>{t('edit.labelBorderColor')}</IonLabel>
							<RgbStringColorPicker color={borderColor} onChange={setBorderColor} />
						</IonItem>
					)}
					<IonButton expand='block' onClick={handleSave}>
						{t('edit.btnApply')}
					</IonButton>
				</IonList>
			</IonContent>
		</IonPage>
	);
};

export default ContainerGroupsEditPage;
