import React, { useEffect, useState } from 'react';
import { IonAvatar, IonCard, IonImg, IonItem, IonLabel, IonSkeletonText } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import './Profile.css';
import { ProfileDisplay } from '../models';

const Profile = ({ profile }: { profile: ProfileDisplay }) => {
	const { t } = useTranslation();
	const [displayName, setDisplayName] = useState<string>('');
	const [displayImage, setDisplayImage] = useState<string>('/assets/avatar.svg');
	const [loaded, setLoaded] = useState<boolean>(false);

	useEffect(() => {
		setDisplayName(profile?.displayName || t('profile.labelDefault'));
		setDisplayImage(profile?.image || '/assets/avatar.svg');
		setLoaded(true);
	}, [t, profile]);

	const createProfile = () => {
		const disableSkeleton = true;

		if (disableSkeleton) {
			return (
				<IonItem className='profile-item'>
					<IonAvatar slot='start'>
						<img alt={`${displayName} Avatar`} src={displayImage} />
					</IonAvatar>
					<IonLabel>{displayName}</IonLabel>
				</IonItem>
			);
		} else {
			return (
				<IonItem className='profile-item'>
					<IonAvatar slot='start'>
						{(!loaded || profile?.shouldAvoidLoad) && <IonSkeletonText animated={true}></IonSkeletonText>}
						<IonImg
							alt='Avatar'
							src={displayImage}
							onIonImgDidLoad={() => setLoaded(true)}
							onIonImgWillLoad={() => setLoaded(false)}
							className={loaded && !profile?.shouldAvoidLoad ? '' : 'hidden'}
						/>
					</IonAvatar>
					<IonLabel>{!profile?.shouldAvoidLoad ? displayName : <IonSkeletonText animated={true}></IonSkeletonText>}</IonLabel>
				</IonItem>
			);
		}
	};

	return <IonCard className='profile'>{createProfile()}</IonCard>;
};

export default Profile;
