import React, { useContext, useEffect, useState } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth as firebaseAuth, db } from './firebase';
import { doc, getDoc } from 'firebase/firestore';
import { Auth, AuthInit } from './models';

export const AuthContext = React.createContext<Auth>({ loggedIn: false });

export const useAuth = (): Auth => {
	return useContext(AuthContext);
};

export const useAuthInit = (): AuthInit => {
	const [authInit, setAuthInit] = useState<AuthInit>({ loading: true });

	useEffect(() => {
		return onAuthStateChanged(
			firebaseAuth,
			(firebaseUser) => {
				const auth = firebaseUser ? { loggedIn: true, userId: firebaseUser.uid, userSettings: {}, userProfile: {} } : { loggedIn: false };

				if (firebaseUser && firebaseUser.uid) {
					Promise.all([getDoc(doc(db, 'users', auth.userId, 'settings', 'app')), getDoc(doc(db, 'users', auth.userId, 'profile', 'data'))])
						.then((doc) => {
							auth.userSettings = doc[0].data();
							auth.userProfile = doc[1].data();
						})
						.catch((err) => {
							console.log('1', err);
						})
						.finally(() => setAuthInit({ loading: false, auth }));
				} else {
					setAuthInit({ loading: false, auth });
				}
			},
			(error) => console.log('2', error)
		);
	}, []);

	return authInit;
};
