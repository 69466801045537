import React from 'react';
import { IonThumbnail, IonImg } from '@ionic/react';
import { ContainerModel } from '../models';
import './Container.css';

const containerClass = (data: ContainerModel): string[] => {
	const classes = [];

	if (!data.active) classes.push('container--starter');
	if (data.active && !data.thumbnail) classes.push('container--only-emoji');
	if (data.isParent) classes.push('container--parent');
	if (data.isGrayscale) classes.push('container--grayscale');
	if (data.isTransparent) classes.push('container--transparent');
	if (data.isShortcut) classes.push('container--shortcut');

	return classes;
};

const thumbnail = (data: ContainerModel): string => {
	if (data.thumbnail) return data.thumbnail;
	if (data.background) return '/assets/images/empty.png';
	return '/assets/images/empty.png';
};

const Container = ({ data, handler }: { data: ContainerModel; handler?: any }) => {
	return (
		<div className={`container ${containerClass(data).join(' ')}`} data-id={data.id} data-is-parent={data.isParent} data-is-shortcut={data.isShortcut}>
			<IonThumbnail className='container-thumbnail'>
				<IonImg
					className='container-image'
					alt={`${data.name}`}
					src={thumbnail(data)}
					style={{ background: data.background, border: data.border }}
				></IonImg>
				<div className='container-thumbnail emoji'>{data.emoji}</div>
				<div {...handler} className='container-events'></div>
			</IonThumbnail>
			<div className='container-name'>{data.name}</div>
		</div>
	);
};

export default Container;
