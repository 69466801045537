import React, { useEffect, useState } from 'react';
import { IonRouterOutlet, IonSplitPane } from '@ionic/react';
import { Route, Redirect } from 'react-router-dom';
import { useAuth } from './auth';
import ContainerDisplayPage from './pages/ContainerDisplayPage';
import SettingsPage from './pages/SettingsPage';
import ContainerEditPage from './pages/ContainerEditPage';
import ContainerCreatePage from './pages/ContainerCreatePage';
import Menu from './components/Menu';
import ContainerViewPage from './pages/ContainerViewPage';
import ContainerSearchPage from './pages/ContainerSearchPage';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { pageTitles } from './pages/helpers/pageTitles';
import { ContainerModel, UserProfile } from './models';
import GroupsListPage from './pages/GroupsListPage';
import GroupsCreatePage from './pages/GroupsCreatePage';
import ContainerDisplayGroupsPage from './pages/ContainerDisplayGroupsPage';
import GroupsDetailsPage from './pages/GroupsDetailsPage';
import ContainerGroupsViewPage from './pages/ContainerGroupsViewPage';
import ContainerGroupsEditPage from './pages/ContainerGroupsEditPage';
import ContainerGroupsCreatePage from './pages/ContainerGroupsCreatePage';

const AppPane: React.FC = () => {
	const { loggedIn, userSettings, userProfile } = useAuth();
	const history = useHistory();
	const { i18n } = useTranslation();
	const [lastCollection, setLastCollection] = useState<string>('');
	const [searchTerm, setSearchTerm] = useState<string>('');
	const [theme, setTheme] = useState<string>('dark');
	const [profile, setProfile] = useState<UserProfile>({});
	const [overlayContainer, setOverlayContainer] = useState<ContainerModel>(null);
	const [savedContainers, setSavedContainers] = useState<ContainerModel[]>([]);
	const [savedColUrls, setSavedColUrls] = useState<string[]>([]);
	const [savedColGroupsUrls, setSavedColGroupsUrls] = useState<string[]>([]);

	useEffect(() => {
		if (userSettings?.language) {
			i18n.changeLanguage(userSettings.language);
			document.title = pageTitles[userSettings.language];
		}
		if (userSettings?.theme) {
			document.body.setAttribute('color-theme', userSettings.theme);
			setTheme(userSettings.theme);
		}
	}, [i18n, userSettings]);

	useEffect(() => {
		setProfile(userProfile);
	}, [userProfile]);

	const addContainerToSaved = (container: ContainerModel) => {
		let tempArr: ContainerModel[] = savedContainers.filter((cont) => cont.id !== container.id);
		if (tempArr.length === 10) tempArr = tempArr.slice(0, 9);

		tempArr.unshift(container);
		setSavedContainers(tempArr);
	};

	const deleteAllSavedContainers = () => {
		setSavedContainers([]);
	};

	const removeSavedContainer = (savedId) => {
		const tempArr: ContainerModel[] = savedContainers.filter((cont) => cont.id !== savedId);
		setSavedContainers(tempArr);
	};

	const addToSavedCollectionUrls = (url: string, arr: string[], type: number) => {
		const createNewArr = (url: string, arr: string[]) => {
			const lastEntry = arr.at(-1);
			const newArr = arr.slice();

			if (lastEntry === url) {
				return newArr;
			} else {
				newArr.push(url);
				return newArr;
			}
		};

		const newColUrls = createNewArr(url, arr);

		if (type === 1) {
			setSavedColUrls((prevState) => [...prevState, ...newColUrls]);
		} else {
			setSavedColGroupsUrls(newColUrls);
		}
	};

	const removeLastSavedColUrl = (arr: string[], type: number) => {
		const newArr = arr.filter((i, index) => arr[index - 1] !== i);
		newArr.pop();
		const lastUrl = newArr.at(-1);

		if (type === 1) {
			setSavedColUrls(newArr);
		} else {
			setSavedColGroupsUrls(newArr);
		}

		return lastUrl;
	};

	if (!loggedIn) {
		return <Redirect to='/login' />;
	}

	return (
		<IonSplitPane contentId='main'>
			<Menu theme={theme} profile={profile} />
			<IonRouterOutlet id='main'>
				<Route path='/app/collection/:id' exact={true}>
					<ContainerDisplayPage
						setSearchTerm={setSearchTerm}
						setLastCollection={setLastCollection}
						redirectSearch={() => {
							history.push('/app/search');
						}}
						theme={theme}
						overlayContainer={overlayContainer}
						setOverlayContainer={setOverlayContainer}
						savedContainers={savedContainers}
						addContainerToSaved={addContainerToSaved}
						deleteAllSavedContainers={deleteAllSavedContainers}
						removeSavedContainer={removeSavedContainer}
						savedUrls={savedColUrls}
						addToSavedUrls={addToSavedCollectionUrls}
						removeLastSavedUrl={removeLastSavedColUrl}
					/>
				</Route>
				<Route path='/app/view/:parentId/:id' exact={true}>
					<ContainerViewPage />
				</Route>
				<Route path='/app/edit/:parentId/:id' exact={true}>
					<ContainerEditPage />
				</Route>
				<Route path='/app/create/:parentId/:position' exact={true}>
					<ContainerCreatePage />
				</Route>
				<Route path='/app/search' exact={true}>
					<ContainerSearchPage
						setSearchTerm={setSearchTerm}
						searchTerm={searchTerm}
						lastCollection={lastCollection}
						savedContainers={savedContainers}
						addContainerToSaved={addContainerToSaved}
						deleteAllSavedContainers={deleteAllSavedContainers}
						removeSavedContainer={removeSavedContainer}
					/>
				</Route>
				<Route path='/app/config'>
					<SettingsPage theme={theme} setTheme={setTheme} profile={profile} setProfile={setProfile} />
				</Route>
				<Route path='/app/groups/list' exact={true}>
					<GroupsListPage />
				</Route>
				<Route path='/app/groups/new' exact={true}>
					<GroupsCreatePage />
				</Route>
				<Route path='/app/groups/collection/:owner/:groupId/:id' exact={true}>
					<ContainerDisplayGroupsPage
						theme={theme}
						overlayContainer={overlayContainer}
						setOverlayContainer={setOverlayContainer}
						savedContainers={savedContainers}
						addContainerToSaved={addContainerToSaved}
						deleteAllSavedContainers={deleteAllSavedContainers}
						removeSavedContainer={removeSavedContainer}
						savedUrls={savedColGroupsUrls}
						addToSavedUrls={addToSavedCollectionUrls}
						removeLastSavedUrl={removeLastSavedColUrl}
					/>
				</Route>
				<Route path='/app/groups/details/:owner/:groupId/'>
					<GroupsDetailsPage />
				</Route>
				<Route path='/app/groups/view/:owner/:groupId/:parentId/:id' exact={true}>
					<ContainerGroupsViewPage />
				</Route>
				<Route path='/app/groups/edit/:owner/:groupId/:parentId/:id' exact={true}>
					<ContainerGroupsEditPage />
				</Route>
				<Route path='/app/groups/create/:owner/:groupId/:parentId/:position' exact={true}>
					<ContainerGroupsCreatePage />
				</Route>
			</IonRouterOutlet>
		</IonSplitPane>
	);
};

export default AppPane;
