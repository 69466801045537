import React, { useEffect, useRef, useState } from 'react';
import {
	IonButton,
	IonButtons,
	IonContent,
	IonHeader,
	IonIcon,
	IonInput,
	IonItem,
	IonLabel,
	IonList,
	IonMenuButton,
	IonModal,
	IonPage,
	IonSelect,
	IonSelectOption,
	IonTextarea,
	IonTitle,
	IonToast,
	IonToolbar,
	isPlatform,
	useIonLoading,
	useIonToast,
	useIonViewWillEnter,
	useIonViewWillLeave,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../auth';
import './ContainerViewPage.css';
import ReactCrop, { centerCrop, Crop, makeAspectCrop, PixelCrop } from 'react-image-crop';
import { compressToJPEG } from './helpers/compressImage';
import { imgPreview } from './helpers/imgPreview';
import './GroupsDetailsPage.css';
import { doc, setDoc, Timestamp, onSnapshot, query, where, collectionGroup, getDocs } from 'firebase/firestore';
import { db, storage } from '../firebase';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { chevronBackCircle as backIcon, add as addMemberIcon } from 'ionicons/icons';
import { useHistory, useParams } from 'react-router';
import { GroupInfo, toGroupInfo } from '../models';
import { OverlayEventDetail } from '@ionic/react/dist/types/components/react-component-lib/interfaces';
import ProfileTile from '../components/ProfileTile';

interface PageParams {
	owner?: string;
	groupId?: string;
}

const GroupsDetailsPage: React.FC = () => {
	const { t } = useTranslation();
	const { userId } = useAuth();
	const { owner, groupId } = useParams<PageParams>();
	const history = useHistory();
	const [name, setName] = useState<string>('');
	const [bio, setBio] = useState<string>('');
	const [imageURL, setImageURL] = useState<string>('/assets/images/placeholder.png');
	const [thumbnailURL, setThumbnailURL] = useState<string>('/assets/images/placeholder.png');
	const [crop, setCrop] = useState<Crop>();
	const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
	const [isNewImage, setIsNewImage] = useState<boolean>(false);
	const [showToast, setShowToast] = useState<boolean>(false);
	const [toastMsg, setToastMsg] = useState<string>('');
	const [forceLoad, setForceLoad] = useState<number>(0);
	const [groupInfo, setGroupInfo] = useState<GroupInfo>();
	const [shouldCheckLevel, setShouldCheckLevel] = useState<boolean>(false);
	const [accessLevel, setAccessLevel] = useState<number>(0);
	const [groupMembers, setGroupMembers] = useState<GroupMember[]>([]);
	const fileInputRef = useRef<HTMLInputElement>();
	const imgRef = useRef<HTMLImageElement>();
	const [presentLoading, dismissLoading] = useIonLoading();
	const [presentToast] = useIonToast();
	const modal = useRef<HTMLIonModalElement>(null);
	const newUserInput = useRef<HTMLIonInputElement>(null);

	const LEVELS = {
		1: 'admin',
		2: 'editor',
		3: 'reader',
	};

	interface GroupMember {
		id: string;
		displayName: string;
		image: string;
		level: number;
	}

	const sortGroupMembers = (members: GroupMember[]) => {
		return members.sort((a, b) => a.level - b.level || a.displayName.localeCompare(b.displayName));
	};

	const init = () => {
		setName('');
		setBio('');
		setImageURL('/assets/images/placeholder.png');
		setThumbnailURL('/assets/images/placeholder.png');
		setIsNewImage(false);
		setGroupMembers([]);
	};

	useIonViewWillEnter(() => {
		setForceLoad(forceLoad + 1);
	}, []);

	useIonViewWillLeave(() => {
		init();
	}, []);

	useEffect(() => {
		const loadingError = () => {
			presentToast({
				message: t('display.toastLoadError'),
				duration: 1000,
				position: 'bottom',
			});
			history.push(`/app/groups/list`);
		};

		try {
			if (owner === undefined || owner === 'undefined' || groupId === undefined || groupId === 'undefined') {
				loadingError();
			}

			// Group Info
			const groupRef = doc(db, 'users', owner, 'groups', groupId);
			const unsubscribeInfo = onSnapshot(groupRef, (doc) => {
				if (!doc.exists()) loadingError();

				setGroupInfo(toGroupInfo(doc));
				setShouldCheckLevel(true);
			});

			return () => {
				unsubscribeInfo();
			};
		} catch (e) {
			loadingError();
		}
	}, [owner, groupId, userId, t, presentToast, history, forceLoad]);

	useEffect(() => {
		const loadingError = () => {
			presentToast({
				message: t('group.details.errorLoad'),
				duration: 1000,
				position: 'bottom',
			});
			history.push(`/app/groups/list`);
		};

		const sortGroupMembers = (members: GroupMember[]) => {
			return members.sort((a, b) => a.level - b.level || a.displayName.localeCompare(b.displayName));
		};

		if (!shouldCheckLevel) return;
		if (!groupInfo.memberAccess) return;

		// 1 - Check if the user is part of the members
		const memberInfo = groupInfo.memberAccess.find((member) => member.id === userId);
		if (!memberInfo) loadingError();

		// 2 - Store the current user's role on the state
		setAccessLevel(memberInfo.level);

		// 3 - Set the values for the items on the page
		setName(groupInfo.displayName);
		setBio(groupInfo.bio);
		setImageURL(groupInfo.fullImage);
		setThumbnailURL(groupInfo.image);
		setIsNewImage(false);

		// 4 - Look up the profile data for each member
		const updateMemberData = async () => {
			const members = [];
			const q = query(collectionGroup(db, 'profile'), where('id', 'in', groupInfo.members));
			const snapshot = await getDocs(q);

			snapshot.forEach((doc) => {
				members.push({
					id: doc.data().id,
					displayName: doc.data().displayName || '',
					image: doc.data().image || '',
					level: groupInfo.memberAccess.find((m) => m.id === doc.data().id).level,
				});
			});

			setGroupMembers(sortGroupMembers(members));
		};

		updateMemberData();
	}, [groupInfo, history, presentToast, shouldCheckLevel, t, userId]);

	const canEdit = () => {
		return accessLevel === 1;
	};

	const handleFileChange = async (event) => {
		if (event.target.files.length === 0) return;

		setCrop(undefined);
		const file = event.target.files.item(0);
		const compressedFile = await compressToJPEG(file, 200);
		const imageURL = URL.createObjectURL(compressedFile);
		setImageURL(imageURL);
	};

	const handleImageClick = async () => {
		if (isPlatform('capacitor')) {
			fileInputRef.current.click();
		} else {
			fileInputRef.current.click();
		}
	};

	const onImageLoad = (e) => {
		const { naturalWidth: width, naturalHeight: height } = e.currentTarget;

		const crop = centerCrop(
			makeAspectCrop(
				{
					unit: '%',
					width: 40,
				},
				1,
				width,
				height
			),
			width,
			height
		);

		setCrop(crop);
	};

	useEffect(() => {
		const generateThumbnail = async () => {
			if (isNewImage && completedCrop?.width && completedCrop?.height && imgRef.current) {
				const thumb = await imgPreview(imgRef.current, completedCrop);
				setThumbnailURL(thumb);
			}
		};

		generateThumbnail();
	}, [completedCrop, imageURL, isNewImage]);

	useEffect(() => {
		return () => {
			if (imageURL.startsWith('blob:')) {
				URL.revokeObjectURL(imageURL);
			}
		};
	}, [imageURL]);

	const handleClickBtnChange = () => {
		if (isNewImage) {
			if (imageURL.startsWith('blob:')) URL.revokeObjectURL(imageURL);
		}

		if (!isNewImage) fileInputRef.current.click();

		setIsNewImage(!isNewImage);
	};

	const handleBackButton = () => {
		history.goBack();
	};

	const handleChangeUserLevel = (id, level) => {
		const newMembers = groupMembers;
		const index = groupMembers.findIndex((member) => member.id === id);
		newMembers[index].level = level;
		setGroupMembers(sortGroupMembers(newMembers));
	};

	const confirm = () => {
		modal.current?.dismiss(newUserInput.current?.value, 'confirm');
	};

	const onWillDismiss = async (ev: CustomEvent<OverlayEventDetail>) => {
		if (ev.detail.role === 'confirm' && ev.detail.data) {
			await presentLoading({
				message: t('groups.edit.loadingSearchUser'),
			});

			// Search for the user that matches the username
			const q = query(collectionGroup(db, 'profile'), where('displayName', '==', ev.detail.data));
			const snapshot = await getDocs(q);

			if (snapshot.empty) {
				await dismissLoading();

				await presentToast({
					message: t('groups.edit.toastUserNotFound'),
					duration: 2000,
					position: 'bottom',
				});

				return;
			}

			const user = snapshot.docs[0].data();

			// Check if it's already part of the members array
			if (groupMembers.find((member) => member.id === user.id)) {
				await dismissLoading();

				await presentToast({
					message: t('groups.edit.toastUserAlreadyInGroup'),
					duration: 2000,
					position: 'bottom',
				});

				return;
			}

			// Add it to the group members array
			const newMembers = [];

			groupMembers.forEach((member) => newMembers.push(member));

			newMembers.push({
				id: user.id,
				displayName: user.displayName || '',
				image: user.image || '',
				level: 3,
			});

			setGroupMembers(sortGroupMembers(newMembers));

			await dismissLoading();
		}
	};

	const handleSave = async () => {
		const uploadImages = async (userId, groupId, imageUrl, thumbnailUrl): Promise<string[]> => {
			const [imageResponse, thumbResponse] = await Promise.all([fetch(imageUrl), fetch(thumbnailUrl)]);
			const [imageBlob, thumbBlob] = await Promise.all([imageResponse.blob(), thumbResponse.blob()]);

			const pictureRef = ref(storage, `users/${userId}/groups/${groupId}/banner/image`);
			const thumbRef = ref(storage, `users/${userId}/groups/${groupId}/banner/thumbnail`);

			const [imageTask, thumbTask] = await Promise.all([uploadBytes(pictureRef, imageBlob), uploadBytes(thumbRef, thumbBlob)]);

			return await Promise.all([getDownloadURL(imageTask.ref), getDownloadURL(thumbTask.ref)]);
		};

		if (!name) {
			setToastMsg(t('groups.create.toastNameEmpty'));
			setShowToast(true);
			return;
		}

		if (imageURL.startsWith('/assets')) {
			setToastMsg(t('groups.create.toastImageEmpty'));
			setShowToast(true);
			return;
		}

		await presentLoading({
			message: t('groups.edit.loadingApply'),
		});

		const membersArr = [];
		const levelsArr = [];
		const toRemove = [];

		groupMembers.forEach((member) => {
			if (member.level > 0) {
				membersArr.push(member.id);
				levelsArr.push({ id: member.id, level: member.level });
			} else {
				toRemove.push(member.id);
			}
		});

		if (isNewImage) {
			const [newImageUrl, newThumbUrl] = await uploadImages(owner, groupId, imageURL, thumbnailURL);

			await setDoc(
				doc(db, 'users', owner, 'groups', groupId),
				{
					displayName: name,
					bio: bio,
					members: membersArr,
					memberAccess: levelsArr,
					lastUpdate: Timestamp.now(),
					fullImage: newImageUrl,
					image: newThumbUrl,
				},
				{ merge: true }
			);
		} else {
			await setDoc(
				doc(db, 'users', owner, 'groups', groupId),
				{
					displayName: name,
					bio: bio,
					members: membersArr,
					memberAccess: levelsArr,
					lastUpdate: Timestamp.now(),
				},
				{ merge: true }
			);
		}

		await dismissLoading();

		history.push(`/app/groups/collection/${owner}/${groupId}/main`);
	};

	const createMembersHTML = () => {
		const showSelect = (level) => {
			if (level === 1) return false;
			return canEdit();
		};

		if (groupMembers.length > 0) {
			return groupMembers?.map((member) => {
				return (
					<IonItem key={member.id} className='member-profile half-tile'>
						<ProfileTile profile={member} />
						{!showSelect(member.level) && <IonLabel style={{ textAlign: 'right' }}>{t(`groups.edit.levelNames.${LEVELS[member.level]}`)}</IonLabel>}
						{showSelect(member.level) && (
							<IonSelect
								className='level-select'
								value={member.level.toString()}
								onIonChange={(e) => handleChangeUserLevel(member.id, Number(e.detail.value))}
								style={{ textAlign: 'right' }}
							>
								<IonSelectOption value='2'>{t('groups.edit.levelNames.editor')}</IonSelectOption>
								<IonSelectOption value='3'>{t('groups.edit.levelNames.reader')}</IonSelectOption>
								<IonSelectOption value='0'>{t('groups.edit.levelNames.remove')}</IonSelectOption>
							</IonSelect>
						)}
					</IonItem>
				);
			});
		} else {
			const emptyMember = {
				id: 'empty',
				shouldAvoidLoad: true,
			};

			return (
				<IonItem key={emptyMember.id} className='member-profile'>
					<ProfileTile profile={emptyMember} />
				</IonItem>
			);
		}
	};

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot='start'>
						<IonMenuButton />
						<IonButton onClick={handleBackButton}>
							<IonIcon slot='icon-only' icon={backIcon} />
						</IonButton>
					</IonButtons>
					<IonTitle>{groupInfo?.displayName}</IonTitle>
				</IonToolbar>
			</IonHeader>

			<IonContent className='ion-padding'>
				<IonList>
					<IonItem lines='full'>
						<IonLabel>{t('groups.create.labelImage')}</IonLabel>
						{!isNewImage && <img src={imageURL} alt='' className='container-uploaded-image' />}
						<input type='file' accept='image/*' alt={t('edit.labelImg')} onChange={handleFileChange} ref={fileInputRef} hidden />
						{!isNewImage && canEdit() && (
							<IonButton slot='end' onClick={handleClickBtnChange}>
								{t('groups.edit.btnChangeImage')}
							</IonButton>
						)}
						{isNewImage && (
							<ReactCrop
								crop={crop}
								onChange={(c) => setCrop(c)}
								aspect={1}
								className='container-crop'
								onComplete={(c) => setCompletedCrop(c)}
								disabled={!isNewImage}
								ruleOfThirds={true}
							>
								<img
									src={imageURL}
									alt=''
									onClick={handleImageClick}
									style={{ cursor: 'pointer' }}
									className='container-uploaded-image'
									onLoad={onImageLoad}
									ref={imgRef}
								/>
							</ReactCrop>
						)}
					</IonItem>
					<IonItem lines='full'>
						<IonLabel>{t('groups.create.labelName')}</IonLabel>
						<IonInput value={name} onIonChange={(e) => setName(e.detail.value)} style={{ textAlign: 'right' }} readonly={!canEdit()} />
					</IonItem>
					<IonItem lines='full'>
						<IonLabel>{t('groups.create.labelBio')}</IonLabel>
						<IonTextarea
							slot='end'
							value={bio}
							rows={1}
							autoGrow={true}
							onIonChange={(e) => setBio(e.detail.value)}
							style={{ textAlign: 'right' }}
							readonly={!canEdit()}
						/>
					</IonItem>
					<IonList lines='full'>{createMembersHTML()}</IonList>
					<IonItem className={canEdit() ? '' : 'hidden'}>
						<IonLabel>{t('groups.edit.labelAddMember')}</IonLabel>
						<IonButton slot='end' id='open-modal'>
							<IonIcon slot='icon-only' icon={addMemberIcon} />
						</IonButton>
					</IonItem>
					{canEdit() && (
						<IonButton expand='block' onClick={handleSave}>
							{t('groups.edit.btnApply')}
						</IonButton>
					)}
				</IonList>
				<IonToast isOpen={showToast} onDidDismiss={() => setShowToast(false)} message={toastMsg} duration={2000} position='bottom'></IonToast>
				<IonModal id='addNewUserModal' ref={modal} trigger='open-modal' onWillDismiss={(ev) => onWillDismiss(ev)}>
					<IonHeader>
						<IonToolbar>
							<IonButtons slot='start'>
								<IonButton onClick={() => modal.current?.dismiss()}>{t('groups.edit.btnCancel')}</IonButton>
							</IonButtons>
							<IonTitle className='modal-title'>{t('groups.edit.labelAddMember')}</IonTitle>
							<IonButtons slot='end'>
								<IonButton strong={true} onClick={() => confirm()}>
									{t('groups.edit.btnSearchUser')}
								</IonButton>
							</IonButtons>
						</IonToolbar>
					</IonHeader>
					<IonContent className='ion-padding'>
						<IonItem>
							<IonLabel position='stacked'>{t('groups.edit.labelUsername')}</IonLabel>
							<IonInput ref={newUserInput} type='text' />
						</IonItem>
					</IonContent>
				</IonModal>
			</IonContent>
		</IonPage>
	);
};

export default GroupsDetailsPage;
