import React, { useEffect, useState } from 'react';
import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, useIonToast, useIonViewWillEnter } from '@ionic/react';
import { addCircle as newGroupIcon } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../auth';
import './ContainerViewPage.css';
import { GroupInfo, toGroupInfoArr } from '../models';
import { useHistory } from 'react-router';
import { collectionGroup, query, where, onSnapshot } from 'firebase/firestore';
import { db } from '../firebase';
import Profile from '../components/Profile';

const GroupsListPage: React.FC = () => {
	const { t } = useTranslation();
	const { userId, userProfile } = useAuth();
	const history = useHistory();
	const [groups, setGroups] = useState<GroupInfo[]>([]);
	const [displayGroups, setDisplayGroups] = useState<GroupInfo[]>([]);
	const [presentToast] = useIonToast();

	const defaultGroup: GroupInfo = {
		displayName: t('groups.create.title'),
		image: newGroupIcon,
	};

	useIonViewWillEnter(() => {
		if (!userProfile.displayName) {
			const loadingError = () => {
				presentToast({
					message: t('groups.list.errorNoUsername'),
					duration: 1500,
					position: 'bottom',
				});
				history.push(`/app/config/profile`);
			};

			loadingError();
		}
	});

	useEffect(() => {
		const q = query(collectionGroup(db, 'groups'), where('members', 'array-contains', userId));
		const unsubscribe = onSnapshot(q, ({ docs }) => setGroups(toGroupInfoArr(docs)));

		return () => {
			unsubscribe();
		};
	}, [userId, userProfile, t, presentToast, history]);

	useEffect(() => {
		groups.sort((a, b) => a.displayName.localeCompare(b.displayName));
		setDisplayGroups(groups);
	}, [groups]);

	const handleCreateGroup = () => {
		history.push('/app/groups/new');
	};

	const handleClickGroup = (group) => {
		history.push(`/app/groups/collection/${group.ownerId}/${group.id}/main`);
	};

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot='start'>
						<IonMenuButton />
					</IonButtons>
					<IonTitle>{t('groups.list.title')}</IonTitle>
				</IonToolbar>
			</IonHeader>

			<IonContent className='ion-padding'>
				{displayGroups.map((group) => {
					return (
						<div onClick={() => handleClickGroup(group)} key={group.id}>
							<Profile profile={group} />
						</div>
					);
				})}
				<div onClick={handleCreateGroup}>
					<Profile profile={defaultGroup} />
				</div>
			</IonContent>
		</IonPage>
	);
};

export default GroupsListPage;
