import React, { useEffect, useRef, useState } from 'react';
import {
	IonButton,
	IonButtons,
	IonContent,
	IonHeader,
	IonIcon,
	IonInput,
	IonItem,
	IonLabel,
	IonList,
	IonMenuButton,
	IonPage,
	IonTextarea,
	IonTitle,
	IonToast,
	IonToolbar,
	isPlatform,
	useIonLoading,
	useIonViewWillEnter,
	useIonViewWillLeave,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../auth';
import './ContainerViewPage.css';
import ReactCrop, { centerCrop, Crop, makeAspectCrop, PixelCrop } from 'react-image-crop';
import { compressToJPEG } from './helpers/compressImage';
import { imgPreview } from './helpers/imgPreview';
import './GroupsCreatePage.css';
import { doc, setDoc, Timestamp, addDoc, collection } from 'firebase/firestore';
import { db, storage } from '../firebase';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { chevronBackCircle as backIcon } from 'ionicons/icons';
import { useHistory } from 'react-router';

const GroupsCreatePage: React.FC = () => {
	const { t } = useTranslation();
	const { userId } = useAuth();
	const history = useHistory();
	const [name, setName] = useState<string>('');
	const [bio, setBio] = useState<string>('');
	const [imageURL, setImageURL] = useState<string>('/assets/images/placeholder.png');
	const [thumbnailURL, setThumbnailURL] = useState<string>('/assets/images/placeholder.png');
	const [crop, setCrop] = useState<Crop>();
	const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
	const [isNewImage, setIsNewImage] = useState<boolean>(false);
	const [showToast, setShowToast] = useState<boolean>(false);
	const [toastMsg, setToastMsg] = useState<string>('');
	const fileInputRef = useRef<HTMLInputElement>();
	const imgRef = useRef<HTMLImageElement>();
	const [presentLoading, dismissLoading] = useIonLoading();

	const init = () => {
		setName('');
		setBio('');
		setImageURL('/assets/images/placeholder.png');
		setThumbnailURL('/assets/images/placeholder.png');
		setIsNewImage(false);
	};

	useIonViewWillEnter(() => {
		init();
	}, []);

	useIonViewWillLeave(() => {
		init();
	}, []);

	const handleFileChange = async (event) => {
		if (event.target.files.length === 0) return;

		setCrop(undefined);
		const file = event.target.files.item(0);
		const compressedFile = await compressToJPEG(file, 200);
		const imageURL = URL.createObjectURL(compressedFile);
		setImageURL(imageURL);
	};

	const handleImageClick = async () => {
		if (isPlatform('capacitor')) {
			fileInputRef.current.click();
		} else {
			fileInputRef.current.click();
		}
	};

	const onImageLoad = (e) => {
		const { naturalWidth: width, naturalHeight: height } = e.currentTarget;

		const crop = centerCrop(
			makeAspectCrop(
				{
					unit: '%',
					width: 40,
				},
				1,
				width,
				height
			),
			width,
			height
		);

		setCrop(crop);
	};

	useEffect(() => {
		const generateThumbnail = async () => {
			if (isNewImage && completedCrop?.width && completedCrop?.height && imgRef.current) {
				const thumb = await imgPreview(imgRef.current, completedCrop);
				setThumbnailURL(thumb);
			}
		};

		generateThumbnail();
	}, [completedCrop, imageURL, isNewImage]);

	useEffect(() => {
		return () => {
			if (imageURL.startsWith('blob:')) {
				URL.revokeObjectURL(imageURL);
			}
		};
	}, [imageURL]);

	const handleClickBtnChange = () => {
		if (isNewImage) {
			if (imageURL.startsWith('blob:')) URL.revokeObjectURL(imageURL);
		}

		if (!isNewImage) fileInputRef.current.click();

		setIsNewImage(!isNewImage);
	};

	const handleSave = async () => {
		const uploadImages = async (userId, groupId, imageUrl, thumbnailUrl): Promise<string[]> => {
			const [imageResponse, thumbResponse] = await Promise.all([fetch(imageUrl), fetch(thumbnailUrl)]);
			const [imageBlob, thumbBlob] = await Promise.all([imageResponse.blob(), thumbResponse.blob()]);

			const pictureRef = ref(storage, `users/${userId}/groups/${groupId}/banner/image`);
			const thumbRef = ref(storage, `users/${userId}/groups/${groupId}/banner/thumbnail`);

			const [imageTask, thumbTask] = await Promise.all([uploadBytes(pictureRef, imageBlob), uploadBytes(thumbRef, thumbBlob)]);

			return await Promise.all([getDownloadURL(imageTask.ref), getDownloadURL(thumbTask.ref)]);
		};

		if (!name) {
			setToastMsg(t('groups.create.toastNameEmpty'));
			setShowToast(true);
			return;
		}

		if (imageURL.startsWith('/assets')) {
			setToastMsg(t('groups.create.toastImageEmpty'));
			setShowToast(true);
			return;
		}

		await presentLoading({
			message: t('groups.create.loadingCreate'),
		});

		const docRef = await addDoc(collection(db, 'users', userId, 'groups'), {
			displayName: name,
			bio: bio,
			members: [userId],
			memberAccess: [{ id: userId, level: 1 }],
			lastUpdate: Timestamp.now(),
			ownerId: userId,
		});

		const [newImageUrl, newThumbUrl] = await uploadImages(userId, docRef.id, imageURL, thumbnailURL);

		await Promise.all([
			setDoc(
				doc(db, 'users', userId, 'groups', docRef.id),
				{
					fullImage: newImageUrl,
					image: newThumbUrl,
					lastUpdate: Timestamp.now(),
				},
				{ merge: true }
			),
			setDoc(doc(db, 'users', userId, 'groups', docRef.id, 'collections', 'main'), {}),
		]);

		await dismissLoading();

		history.push(`/app/groups/collection/${userId}/${docRef.id}/main`);
	};

	const handleBackButton = () => {
		history.push(`/app/groups/list`);
	};

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot='start'>
						<IonMenuButton />
						<IonButton onClick={handleBackButton}>
							<IonIcon slot='icon-only' icon={backIcon} />
						</IonButton>
					</IonButtons>
					<IonTitle>{t('groups.create.title')}</IonTitle>
				</IonToolbar>
			</IonHeader>

			<IonContent className='ion-padding'>
				<IonList>
					<IonItem lines='full'>
						<IonLabel>{t('groups.create.labelImage')}</IonLabel>
						<input type='file' accept='image/*' alt={t('edit.labelImg')} onChange={handleFileChange} ref={fileInputRef} hidden />
						{!isNewImage && (
							<IonButton slot='end' onClick={handleClickBtnChange}>
								{t('groups.create.btnChangeImage')}
							</IonButton>
						)}
						{isNewImage && (
							<ReactCrop
								crop={crop}
								onChange={(c) => setCrop(c)}
								aspect={1}
								className='container-crop'
								onComplete={(c) => setCompletedCrop(c)}
								disabled={!isNewImage}
								ruleOfThirds={true}
							>
								<img
									src={imageURL}
									alt=''
									onClick={handleImageClick}
									style={{ cursor: 'pointer' }}
									className='container-uploaded-image'
									onLoad={onImageLoad}
									ref={imgRef}
								/>
							</ReactCrop>
						)}
					</IonItem>
					<IonItem lines='full'>
						<IonLabel>{t('groups.create.labelName')}</IonLabel>
						<IonInput value={name} onIonChange={(e) => setName(e.detail.value)} style={{ textAlign: 'right' }} />
					</IonItem>
					<IonItem lines='full'>
						<IonLabel>{t('groups.create.labelBio')}</IonLabel>
						<IonTextarea
							slot='end'
							value={bio}
							rows={1}
							autoGrow={true}
							onIonChange={(e) => setBio(e.detail.value)}
							style={{ textAlign: 'right' }}
						/>
					</IonItem>
					<IonButton expand='block' onClick={handleSave}>
						{t('groups.create.btnCreate')}
					</IonButton>
				</IonList>
				<IonToast isOpen={showToast} onDidDismiss={() => setShowToast(false)} message={toastMsg} duration={2000} position='bottom'></IonToast>
			</IonContent>
		</IonPage>
	);
};

export default GroupsCreatePage;
