import React from 'react';
import { IonIcon, IonInput, IonItem } from '@ionic/react';
import { close as closeIcon } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';

const SearchBar = ({ handleSearch, cancelSearch }) => {
	const { t } = useTranslation();

	return (
		<IonItem>
			<IonInput placeholder={t('search.placeholder')} onKeyUp={handleSearch}></IonInput>
			<IonIcon icon={closeIcon} slot='end' onClick={cancelSearch} />
		</IonItem>
	);
};

export default SearchBar;
