import React, { useEffect, useRef, useState } from 'react';
import {
	IonButton,
	IonButtons,
	IonContent,
	IonHeader,
	IonIcon,
	IonItem,
	IonLabel,
	IonList,
	IonMenuButton,
	IonPage,
	IonTitle,
	IonToolbar,
	useIonToast,
	useIonViewWillEnter,
	useIonViewWillLeave,
} from '@ionic/react';
import { chevronBackCircle as backIcon } from 'ionicons/icons';
import { useHistory, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { doc, getDoc, Timestamp } from 'firebase/firestore';
import { ContainerModel, toContainer } from '../models';
import Container from '../components/Container';
import { useAuth } from '../auth';
import { db } from '../firebase';
import './ContainerGroupsViewPage.css';

interface PageParams {
	id: string;
	parentId: string;
	owner: string;
	groupId: string;
}

const ContainerGroupsViewPage: React.FC = () => {
	const { id, parentId, owner, groupId } = useParams<PageParams>();
	const { t } = useTranslation();
	const { userId } = useAuth();
	const history = useHistory();
	const tagListRef = useRef<HTMLUListElement>();
	const [container, setContainer] = useState<ContainerModel>();
	const [name, setName] = useState<string>('');
	const [emoji, setEmoji] = useState<string>('');
	const [imageURL, setImageURL] = useState<string>('/assets/images/placeholder.png');
	const [thumbnailURL, setThumbnailURL] = useState<string>('');
	const [description, setDescription] = useState<string>('');
	const [tags, setTags] = useState<string[]>([]);
	const [border, setBorder] = useState<string>('');
	const [backgroundColor, setBackgroundColor] = useState<string>('');
	const [isGrayscale, setIsGrayscale] = useState<boolean>(false);
	const [isTransparent, setIsTransparent] = useState<boolean>(false);
	const [isParent, setIsParent] = useState<boolean>(false);
	const [createdDate, setCreatedDate] = useState<Timestamp>(null);
	const [editDate, setEditDate] = useState<Timestamp>(null);
	const [childCollection, setChildCollection] = useState<string>('');
	const [presentToast] = useIonToast();

	const init = () => {
		setName('');
		setEmoji('');
		setImageURL('/assets/images/placeholder.png');
		setThumbnailURL('');
		setDescription('');
		setTags([]);
		setBorder('1px solid rgba(128, 128, 128, 0.15)');
		setBackgroundColor('rgba(0, 0, 0, 0)');
		setIsTransparent(false);
		setIsGrayscale(false);
		setIsParent(false);
		setCreatedDate(null);
		setEditDate(null);
		setChildCollection('');
	};

	// Data initialization and fetch
	useIonViewWillEnter(() => {
		const getData = async () => {
			const docRef = doc(db, 'users', owner, 'groups', groupId, 'collections', parentId, 'containers', id);
			const docSnap = await getDoc(docRef);

			if (!docSnap.exists()) {
				presentToast({
					message: t('view.toastLoadError'),
					duration: 1000,
					position: 'bottom',
				});
				history.push(`/app/collection/main`);
			}

			setContainer(toContainer(docSnap));
		};

		init();

		getData();
	}, [userId, parentId, id, owner, groupId]);

	useIonViewWillLeave(() => {
		init();
	});

	// Data setter
	useEffect(() => {
		setName(container?.name);
		setEmoji(container?.emoji);
		setImageURL(container?.image || '/assets/images/placeholder.png');
		setThumbnailURL(container?.thumbnail);
		setDescription(container?.description);
		setTags(container?.tags);
		setBorder(container?.border);
		setBackgroundColor(container?.background);
		setIsTransparent(container?.isTransparent || false);
		setIsGrayscale(container?.isGrayscale || false);
		setIsParent(container?.isParent || false);
		setCreatedDate(container?.created || null);
		setEditDate(container?.lastEdit || null);
		setChildCollection(container?.childCollection || '');
	}, [container]);

	const handleGoToCollection = () => {
		history.push(`/app/groups/collection/${owner}/${groupId}/${container.parentId}`);
	};

	const handleEnterCollection = () => {
		history.push(`/app/groups/collection/${owner}/${groupId}/${childCollection}`);
	};

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot='start'>
						<IonMenuButton />
						<IonButton onClick={handleGoToCollection}>
							<IonIcon slot='icon-only' icon={backIcon} />
						</IonButton>
					</IonButtons>
					<IonTitle>{container?.name}</IonTitle>
				</IonToolbar>
			</IonHeader>

			<IonContent className='ion-padding'>
				<IonList>
					<IonItem>
						<IonLabel position='stacked'>{t('view.labelContainer')}</IonLabel> <br />
						<div className='container-display'>
							<Container
								data={{
									id: '',
									parentId: '',
									name,
									image: imageURL,
									thumbnail: thumbnailURL,
									emoji,
									description,
									border,
									tags,
									position: 99999,
									active: true,
									background: backgroundColor,
									isTransparent,
									isGrayscale,
									isParent,
								}}
							></Container>
							<br />
						</div>
					</IonItem>
					{imageURL !== '/assets/images/placeholder.png' && (
						<IonItem>
							<IonLabel position='stacked'>{t('view.labelImg')}</IonLabel> <br />
							<img src={imageURL} alt='' style={{ cursor: 'pointer' }} className='container-uploaded-image' />
						</IonItem>
					)}
					<IonItem>
						<IonLabel position='stacked'>{t('view.labelDescription')}</IonLabel>
						<p>{description}</p>
					</IonItem>
					<IonItem>
						<IonLabel position='stacked'>{t('view.labelTags')}</IonLabel>
						<ul className='tags' ref={tagListRef}>
							{tags?.map((tag) => (
								<li key={tag} slot='start' className='tag' data-tag={tag}>
									{tag}
								</li>
							))}
						</ul>
					</IonItem>
					<IonItem>
						<IonLabel>{t('view.labelCreatedAt')}</IonLabel>
						<div slot='end'>{`${createdDate?.toDate().toLocaleDateString()}, ${createdDate?.toDate().toLocaleTimeString()}`}</div>
					</IonItem>
					{editDate && (
						<IonItem>
							<IonLabel>{t('view.labelLastEdit')}</IonLabel>
							<div slot='end'>{`${editDate?.toDate().toLocaleDateString()}, ${editDate?.toDate().toLocaleTimeString()}`}</div>
						</IonItem>
					)}
					{childCollection && (
						<IonButton expand='block' onClick={handleEnterCollection}>
							{t('view.btnEnterCollection')}
						</IonButton>
					)}
					<IonButton expand='block' onClick={handleGoToCollection}>
						{t('view.btnGoToCollection')}
					</IonButton>
				</IonList>
			</IonContent>
		</IonPage>
	);
};

export default ContainerGroupsViewPage;
