import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getFirestore, enableIndexedDbPersistence } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';

const firebaseConfig = {
	apiKey: 'AIzaSyDymzhaHs5eokFVDsc6CYkym4gIlPVNsb4',
	authDomain: 'lumi-incubador-universal.firebaseapp.com',
	projectId: 'lumi-incubador-universal',
	storageBucket: 'lumi-incubador-universal.appspot.com',
	messagingSenderId: '348091045778',
	appId: '1:348091045778:web:0b96d4719b6fd187ff534b',
	measurementId: 'G-13T105KLQV',
};

const app = initializeApp(firebaseConfig);

export const analytics = getAnalytics(app);

export const auth = getAuth(app);
const googleAuthProvider = new GoogleAuthProvider();
googleAuthProvider.addScope('profile');
googleAuthProvider.addScope('email');

export const storage = getStorage(app);

const db = getFirestore(app);

enableIndexedDbPersistence(db).catch((err) => {
	if (err.code === 'failed-precondition') {
		// Multiple tabs open, persistence can only be enabled
		// in one tab at a time.
	} else if (err.code === 'unimplemented') {
		// The current browser does not support all the
		// features required to enable persistence
	}
});

GoogleAuth.initialize({
	clientId: '348091045778-mqea14j0c4b814thc12r2vaqe1f3s5i0.apps.googleusercontent.com',
	scopes: ['profile', 'email'],
	grantOfflineAccess: true,
});

export { db, googleAuthProvider, GoogleAuth };
