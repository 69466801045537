import React, { Suspense } from 'react';
import { IonApp, setupIonicReact, IonLoading } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route, Switch } from 'react-router-dom';
import './i18n';
import { useAuthInit, AuthContext } from './auth';
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
import RecoveryPage from './pages/RecoveryPage';
import AppPane from './AppPane';

setupIonicReact();

if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
	document.body.setAttribute('color-theme', 'dark');
} else {
	document.body.setAttribute('color-theme', 'light');
}

const App: React.FC = () => {
	const { loading, auth } = useAuthInit();

	if (loading) {
		return <div></div>;
	}

	return (
		<IonApp>
			<Suspense fallback={<IonLoading isOpen={false} />}>
				<AuthContext.Provider value={auth}>
					<IonReactRouter>
						<Switch>
							<Route path='/' exact={true}>
								<Redirect to='/app/collection/main' />
							</Route>
							<Route path='/login' exact={true}>
								<LoginPage />
							</Route>
							<Route path='/register' exact={true}>
								<RegisterPage />
							</Route>
							<Route path='/recovery' exact={true}>
								<RecoveryPage />
							</Route>
							<Route path='/app/'>
								<AppPane />
							</Route>
							<Route>
								<Redirect to='/app/collection/main' />
							</Route>
						</Switch>
					</IonReactRouter>
				</AuthContext.Provider>
			</Suspense>
		</IonApp>
	);
};

export default App;
