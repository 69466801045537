import {
	AdMob,
	BannerAdOptions,
	BannerAdSize,
	BannerAdPosition,
	BannerAdPluginEvents,
	AdMobBannerSize,
	AdOptions,
	AdLoadInfo,
	InterstitialAdPluginEvents,
} from '@capacitor-community/admob';
import { Capacitor } from '@capacitor/core';

export async function initialize(): Promise<void> {
	const { status } = await AdMob.trackingAuthorizationStatus();

	if (status === 'notDetermined') {
	}

	await AdMob.initialize({
		requestTrackingAuthorization: true,
	});
}

export async function showBanner(): Promise<void> {
	AdMob.addListener(BannerAdPluginEvents.Loaded, () => {
		// Subscribe Banner Event Listener
	});

	AdMob.addListener(BannerAdPluginEvents.SizeChanged, (size: AdMobBannerSize) => {
		// Subscribe Change Banner Size
	});

	const options: BannerAdOptions = {
		adId: 'ca-app-pub-6714772902887339/3348328196',
		adSize: BannerAdSize.BANNER,
		position: BannerAdPosition.BOTTOM_CENTER,
		margin: 0,
		isTesting: true,
	};

	await AdMob.showBanner(options);
}

export async function showInterstitial(): Promise<void> {
	AdMob.addListener(InterstitialAdPluginEvents.Loaded, (info: AdLoadInfo) => {
		// Subscribe prepared interstitial
	});

	const options: AdOptions = {
		adId: 'ca-app-pub-6714772902887339/7690079642',
		isTesting: true,
	};
	await AdMob.prepareInterstitial(options);
	await AdMob.showInterstitial();
}

export function isWeb(): boolean {
	return Capacitor.getPlatform() === 'web';
}
