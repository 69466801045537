import React, { useRef, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { IonButton, IonContent, IonIcon, IonInput, IonItem, IonLabel, IonLoading, IonNote, IonPage, IonToast } from '@ionic/react';
import { pencil as registerIcon, arrowBack as backIcon } from 'ionicons/icons';
import { auth, db } from '../firebase';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import { useAuth } from '../auth';
import { useTranslation } from 'react-i18next';
import './LoginPage.css';

const RegisterPage: React.FC = () => {
	const { t, i18n } = useTranslation();
	const { loggedIn } = useAuth();
	const passRef = useRef<HTMLIonInputElement>();
	const repeatRef = useRef<HTMLIonInputElement>();
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [isValidMail, setIsValidMail] = useState<boolean>();
	const [isTouchedMail, setIsTouchedMail] = useState<boolean>(false);
	const [isValidPass, setIsValidPass] = useState<boolean>();
	const [isTouchedPass, setIsTouchedPass] = useState<boolean>(false);
	const [isValidRepeat, setIsValidRepeat] = useState<boolean>();
	const [isTouchedRepeat, setIsTouchedRepeat] = useState<boolean>(false);
	const [showToast, setShowToast] = useState(false);
	const [toastMsg, setToastMsg] = useState('');
	const [loading, setLoading] = useState(false);

	const ERR_MSGS = {
		'auth/email-already-in-use': t('register.errorMsg.emailAlreadyInUse'),
		'auth/too-many-requests': t('register.errorMsg.tooManyRequests'),
		'auth/weak-password': t('register.errorMsg.weakPassword'),
		'auth/invalid-email': t('register.errorMsg.invalidEmail'),
		'auth/user-disabled': t('register.errorMsg.userDisabled'),
	};

	const validateMail = (ev: Event) => {
		const validateEmail = (email: string) => {
			return email.match(
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			);
		};

		const value = (ev.target as HTMLInputElement).value;
		if (value === '') return;
		validateEmail(value) !== null ? setIsValidMail(true) : setIsValidMail(false);
	};

	const validatePass = (ev: Event) => {
		const validatePassword = (pass: string) => {
			return pass.length > 5;
		};

		const value = (ev.target as HTMLInputElement).value;
		if (value === '') return;
		setIsValidPass(validatePassword(value));
	};

	const validateRepeat = (ev: Event) => {
		const validateRepeated = (repeated: string) => {
			return password === repeated;
		};

		const value = (ev.target as HTMLInputElement).value;
		if (value === '') return;
		setIsValidRepeat(validateRepeated(value));
	};

	const handleRegister = async () => {
		try {
			setLoading(true);
			const cred = await createUserWithEmailAndPassword(auth, email, password);
			await Promise.all([
				setDoc(doc(db, 'users', cred.user.uid, 'settings', 'app'), {
					theme: window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light',
					language: i18n.language,
				}),
				setDoc(doc(db, 'users', cred.user.uid, 'collections', 'main'), {
					numChildren: 0,
					childCollections: [],
				}),
			]);
			setLoading(false);
		} catch (e) {
			console.log(e.code);
			setLoading(false);
			setToastMsg(ERR_MSGS[e.code.toString()] || t('register.errorMsg.fallback'));
			setShowToast(true);
		}
	};

	if (loggedIn) {
		return <Redirect to='/app/collection/main' />;
	}

	return (
		<IonPage>
			<IonContent className='ion-padding form'>
				<div className='items'>
					<header>
						<h2>{t('register.title')}</h2>
					</header>
					<div className='input-fields'>
						<IonItem className={`input ${isValidMail && 'ion-valid'} ${isValidMail === false && 'ion-invalid'}`}>
							<IonLabel position='floating'>{t('register.labelMail')}</IonLabel>
							<IonInput
								type='email'
								value={email}
								onIonChange={(e) => setEmail(e.detail.value)}
								onIonInput={(event) => validateMail(event)}
								onIonBlur={() => setIsTouchedMail(true)}
								onKeyUp={(e) => e.key === 'Enter' && passRef.current.setFocus()}
							></IonInput>
							{isTouchedMail && !isValidMail && (
								<IonNote slot='error' className='input-fields-error'>
									{t('register.noteInvalidMail')}
								</IonNote>
							)}
						</IonItem>
						<IonItem className={`input ${isValidPass && 'ion-valid'} ${isValidPass === false && 'ion-invalid'}`}>
							<IonLabel position='floating'>{t('register.labelPassword')}</IonLabel>
							<IonInput
								type='password'
								value={password}
								onIonChange={(e) => setPassword(e.detail.value)}
								onIonInput={(event) => validatePass(event)}
								onIonBlur={() => setIsTouchedPass(true)}
								ref={passRef}
								onKeyUp={(e) => e.key === 'Enter' && repeatRef.current.setFocus()}
							></IonInput>
							{isTouchedPass && !isValidPass && (
								<IonNote slot='error' className='input-fields-error'>
									{t('register.noteInvalidPass')}
								</IonNote>
							)}
						</IonItem>
						<IonItem className={`input ${isValidRepeat && 'ion-valid'} ${isValidRepeat === false && 'ion-invalid'}`}>
							<IonLabel position='floating'>{t('register.labelRepeat')}</IonLabel>
							<IonInput
								type='password'
								onIonInput={(ev) => validateRepeat(ev)}
								onIonBlur={() => setIsTouchedRepeat(true)}
								ref={repeatRef}
								onKeyUp={(e) => e.key === 'Enter' && handleRegister()}
							></IonInput>
							{isTouchedRepeat && !isValidRepeat && (
								<IonNote slot='error' className='input-fields-error'>
									{t('register.noteInvalidRepeat')}
								</IonNote>
							)}
						</IonItem>
					</div>

					<IonButton expand='block' shape='round' className='login-btn' onClick={handleRegister}>
						<IonIcon icon={registerIcon} slot='start'></IonIcon>
						{t('register.btnRegister')}
					</IonButton>

					<IonButton expand='block' color='primary' shape='round' className='login-btn' routerLink='/login'>
						<IonIcon icon={backIcon} slot='start'></IonIcon>
						{t('register.btnGoBack')}
					</IonButton>
				</div>

				<IonToast isOpen={showToast} onDidDismiss={() => setShowToast(false)} message={toastMsg} duration={5000} position='bottom'></IonToast>
				<IonLoading isOpen={loading} />
			</IonContent>
		</IonPage>
	);
};

export default RegisterPage;
